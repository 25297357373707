import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { MUITextField as MUISelect } from 'components/Core/TextField/styles';
import useIsClient from 'hooks/useIsClient';
import styled from 'styled-components';

const Select = ({
  dataCy,
  id,
  placeholder,
  value = '',
  values,
  label,
  className,
  defaultValue,
  dropdownClass,
  formValid = {},
  options,
  multiple,
  handleChange,
  helperText,
  variant = 'filled',
  SelectProps,
  InputProps,
  inputProps,
  InputLabelProps,
  key: selectKey,
  nameType,
  ...otherProps
}) => {
  const [key, setKey] = useState(1);

  useEffect(() => {
    setKey(key + (selectKey || 1));
  }, [selectKey]);

  const onChange = (event) => {
    handleChange?.(id, event.target.value);
  };

  if (!placeholder && multiple) {
    placeholder = options.find((x) => x.value === '')?.label;
    options = options.filter((x) => x.value !== '');
  }

  let currentValue = multiple ? [] : '';

  currentValue =
    values?.[id] || values?.[id] === 0 ? values[id] : value || value === 0 ? value : '';

  if (currentValue !== undefined) {
    currentValue = multiple ? [currentValue || []].flat() : currentValue;
  }

  const removeFocus = () => {
    if (currentValue?.length === 0) {
      // When no option is selected, this removes focus
      setKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <>
      {label && (
        <SelectTitle data-testid={`${id}-label`} htmlFor={id} className="select--title">
          {label}
        </SelectTitle>
      )}
      <MUISelect
        id={id}
        key={key}
        select
        label={placeholder}
        hiddenLabel={!placeholder}
        defaultValue={defaultValue || (multiple ? [] : '')}
        value={currentValue}
        onChange={onChange}
        error={formValid?.[id] === -1}
        className={`${className}`}
        classes={{
          select: 'font-p font-md',
        }}
        helperText={helperText}
        variant={variant}
        SelectProps={{
          multiple,
          onClose: () => {
            removeFocus();
          },
          MenuProps: {
            disableScrollLock: true,
          },
          ...SelectProps,
        }}
        InputProps={{ disableUnderline: true, ...InputProps }}
        inputProps={{
          ...inputProps,
          'data-testid': id && `select-${id}`,
          'data-cy': dataCy,
          'data-optimizely': `select-input-${id}`,
        }}
        InputLabelProps={{
          sx: { top: '1px' },
          'data-optimizely': `select-label-${id}`,
          ...InputLabelProps,
        }}
        {...otherProps}
      >
        {options?.map((item, index) => (
          <MenuItem value={item.value} key={index} data-cy={dataCy && (item.label || item.value)}>
            {item.label || item.value}
          </MenuItem>
        ))}
      </MUISelect>
    </>
  );
};

export default Select;

export const SelectTitle = styled.label`
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-600);
  line-height: 23px;
  color: var(--grey-8);
  margin-bottom: 10px;
`;
