import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';

const StyledModal = styled(Dialog)`
  .modal-container {
    background: white;
    border-radius: 4px;
    position: relative;
    width: ${({ width }) => width || '1196px'};
    max-width: 100% !important;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 9999999;

      &:hover {
        cursor: pointer;
      }
    }

    @media (min-width: 575px) {
      max-width: 100%;
    }
  }
`;

export { StyledModal };
