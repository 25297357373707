/* eslint-disable no-use-before-define */
/* eslint-disable dot-notation */
import pollForDefinition from 'utils/pollForDefinition';

const dnbMapping = {
  dunsFieldName: 'dBDUNSNumber',
  address1FieldName: 'dBAddressLine1',
  address2FieldName: 'dBAddressLine2',
  cityFieldName: 'dBCity',
  stateFieldName: 'dBState',
  postalFieldName: 'dBPostalCode',
  countryFieldName: 'dBCountry',
  // naicsCodeFieldName: '',
  // naicsDescriptionFieldName: '',
  // sicCodeFieldName: '',
  // sicDescriptionFieldName: '',
  revenueFieldName: 'dBAnnualSales',
  employeeSiteCountFieldName: 'dBTotalEmployeeCount',
  // firstNameFieldName: '',
  // lastNameFieldName: '',
  vanityTitleFieldName: 'dBJobTitle',
  // globalUltimateDunsFieldName: '',
  globalUltimatePrimaryNameFieldName: 'dBGlobalUltimateCompanyName',
  domesticUltimateDunsFieldName: 'dBGlobalUltimateDUNS',
  // domesticUltimatePrimaryNameFieldName: '',
  // parentDunsFieldName: '',
  // parentPrimaryNameFieldName: '',
  // globalUltimateFamilyTreeMembersCountFieldName: '',
  phoneFieldName: 'dBPersonalPhone',
  // telephoneNumberFieldName: '',
  domainFieldName: 'dBDomain',
  tradeStyleNameFieldName: 'dBTradeStyleName',
  // currencyFieldName: '',
};

const initDnB = (formId, writeToState) => {
  pollForDefinition(window, 'Fill', () => {
    const company = document.querySelector(`#${formId} input[name='Company']`);
    const email = document.querySelector(`#${formId} input[name='Email']`);
    const country = document.querySelector(`#${formId} input[name='country']`);

    if (!company || !email) {
      console.log('initDNB: cannot find company or email. Form id: ', formId);
      return;
    }

    const countryId = `${formId}-country`;

    if (!country) {
      const form = document.querySelector(`#${formId}`);
      const countryField = document.createElement('input');
      countryField.type = 'hidden';
      countryField.id = countryId;
      countryField.name = 'country';
      countryField.value = 'US';
      form.appendChild(countryField);
    }

    // fix until DnB can fix their ariaExpanded behavior
    company.addEventListener('blur', (e) => {
      e.target.ariaExpanded = false;
    });

    const dpa = new Fill.LeadFormApp({
      visitorIntelligenceApiKey: 'PAvff2704',
      defaultCompanyCountry: 'US',
      // companyCountryFilter: ['US', 'CA'],
      leadFormName: formId,
      companyCountrySearchFieldName: countryId,
      contactEmailSearchFieldName: email.id,
      // companyNameSearchFieldName: company.id,
      companyNameFieldName: company.name,
      useLIDropdowns: true,
      visitorIDEnabled: false,
      searchCompanyAfterCountryChange: false,
      clearCompanyAfterCountryChange: false,
      clearFieldsIfNoEmailSearchMatch: false,
      attributeForFieldLookup: 'id',
      initialFocusFieldName: '',
      viffResponse: (data) => data[company.name] && writeToState(data),
      ...dnbMapping,
    });
    dpa.attach();
  });
};

export default initDnB;
