import { useEffect, useState } from 'react';
import isNil from './isNil';

const backgroundColorHex = {
  'Background Image': 'transparent',
  Black: '#17191c',
  'Choose a value': 'transparent',
  'Critical 1': '#f4bfbf',
  'Critical 2': '#ee9696',
  'Critical 3': '#e24f4f',
  'Critical 4': '#b53f3f',
  'Critical 5': '#872f2f',
  'Critical 6': '#6c2626',
  Dark: '#22252a',
  Darkest: '#17191c',
  Green: '#18a761',
  'Green Light': '#e1fdef',
  'Grey 1': '#f5f5f5',
  'Grey 2': '#eeeeee',
  'Grey 3': '#dfe0e1',
  'Grey 4': '#bcbcbd',
  'Grey 5': '#949596',
  'Grey 6': '#737475',
  'Grey 7': '#606162',
  'Grey 8': '#444445',
  'Skyline 1': '#e8f4fe',
  'Skyline 2': '#a8d6fb',
  'Skyline 3': '#6dbaf8',
  'Skyline 4': '#5795c6',
  'Skyline 5': '#416f94',
  'Skyline 6': '#274359',
  'Titan Blue 1': '#afcbf9',
  'Titan Blue 2': '#7baaf5',
  'Titan Blue 3': '#2270ee',
  'Titan Blue 4': '#1b5abe',
  'Titan Blue 5': '#14438e',
  'Titan Blue 6': '#103672',
  Transparent: 'transparent',
  White: '#ffffff',
  Yellow: '#ffbe00',
  'Yellow Light': '#ffe278',
};
const colorTypeHelper = {
  'Background Image': '--transparent',
  Black: '--darkest',
  'Choose a value': '--transparent',
  'Critical 1': '--critical-1',
  'Critical 2': '--critical-2',
  'Critical 3': '--critical-3',
  'Critical 4': '--critical-4',
  'Critical 5': '--critical-5',
  'Critical 6': '--critical-6',
  Dark: '--dark',
  Darkest: '--darkest',
  Green: '--green',
  'Green Light': '--green-light',
  'Grey 1': '--grey-1',
  'Grey 2': '--grey-2',
  'Grey 3': '--grey-3',
  'Grey 4': '--grey-4',
  'Grey 5': '--grey-5',
  'Grey 6': '--grey-6',
  'Grey 7': '--grey-7',
  'Grey 8': '--grey-8',
  'Skyline 1': '--skyline-1',
  'Skyline 2': '--skyline-2',
  'Skyline 3': '--skyline-3',
  'Skyline 4': '--skyline-4',
  'Skyline 5': '--skyline-5',
  'Skyline 6': '--skyline-6',
  'Titan Blue 1': '--titan-blue-1',
  'Titan Blue 2': '--titan-blue-2',
  'Titan Blue 3': '--titan-blue-3',
  'Titan Blue 4': '--titan-blue-4',
  'Titan Blue 5': '--titan-blue-5',
  'Titan Blue 6': '--titan-blue-6',
  Transparent: '--transparent',
  White: '--white',
  Yellow: '--yellow',
  'Yellow Light': '--yellow-light',
};

const varColorHex = {
  '--transparent': '#ffffff00',
  '--white': '#ffffff',
  '--black': '#17191c',
  '--darkest': '#17191c',
  '--dark': '#22252a',
  '--green': '#18a761',
  '--green-light': '#e1fdef',
  '--yellow': '#ffbe00',
  '--yellow-light': '#ffe278',
  '--titan-blue-hover': '#2270ee10',
  '--titan-blue-6': '#103672',
  '--titan-blue-5': '#14438e',
  '--titan-blue-4': '#1b5abe',
  '--titan-blue-3': '#0265dc',
  '--titan-blue-2': '#7baaf5',
  '--titan-blue-1': '#afcbf9',
  '--skyline-6': '#274359',
  '--skyline-5': '#416f94',
  '--skyline-4': '#5795c6',
  '--skyline-3': '#6dbaf8',
  '--skyline-2': '#a8d6fb',
  '--skyline-1': '#e8f4fe',
  '--grey-8': '#444445',
  '--grey-7': '#606162',
  '--grey-6': '#737475',
  '--grey-5': '#949596',
  '--grey-4': '#bcbcbd',
  '--grey-3': '#dfe0e1',
  '--grey-2': '#eeeeee',
  '--grey-1': '#f5f5f5',
  '--critical-6': '#6c2626',
  '--critical-5': '#872f2f',
  '--critical-4': '#b53f3f',
  '--critical-3': '#e24f4f',
  '--critical-2': '#ee9696',
  '--critical-1': '#f4bfbf',
};

const nameColorHex = {
  aliceblue: '#f0f8ff',
  antiquewhite: '#faebd7',
  aqua: '#00ffff',
  aquamarine: '#7fffd4',
  azure: '#f0ffff',
  beige: '#f5f5dc',
  bisque: '#ffe4c4',
  black: '#000000',
  blanchedalmond: '#ffebcd',
  blue: '#0000ff',
  blueviolet: '#8a2be2',
  brown: '#a52a2a',
  burlywood: '#deb887',
  cadetblue: '#5f9ea0',
  chartreuse: '#7fff00',
  chocolate: '#d2691e',
  coral: '#ff7f50',
  cornflowerblue: '#6495ed',
  cornsilk: '#fff8dc',
  crimson: '#dc143c',
  cyan: '#00ffff',
  darkblue: '#00008b',
  darkcyan: '#008b8b',
  darkgoldenrod: '#b8860b',
  darkgray: '#a9a9a9',
  darkgreen: '#006400',
  darkkhaki: '#bdb76b',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorange: '#ff8c00',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darksalmon: '#e9967a',
  darkseagreen: '#8fbc8f',
  darkslateblue: '#483d8b',
  darkslategray: '#2f4f4f',
  darkturquoise: '#00ced1',
  darkviolet: '#9400d3',
  deeppink: '#ff1493',
  deepskyblue: '#00bfff',
  dimgray: '#696969',
  dodgerblue: '#1e90ff',
  firebrick: '#b22222',
  floralwhite: '#fffaf0',
  forestgreen: '#228b22',
  fuchsia: '#ff00ff',
  gainsboro: '#dcdcdc',
  ghostwhite: '#f8f8ff',
  gold: '#ffd700',
  goldenrod: '#daa520',
  gray: '#808080',
  green: '#008000',
  greenyellow: '#adff2f',
  honeydew: '#f0fff0',
  hotpink: '#ff69b4',
  'indianred ': '#cd5c5c',
  indigo: '#4b0082',
  ivory: '#fffff0',
  khaki: '#f0e68c',
  lavender: '#e6e6fa',
  lavenderblush: '#fff0f5',
  lawngreen: '#7cfc00',
  lemonchiffon: '#fffacd',
  lightblue: '#add8e6',
  lightcoral: '#f08080',
  lightcyan: '#e0ffff',
  lightgoldenrodyellow: '#fafad2',
  lightgrey: '#d3d3d3',
  lightgreen: '#90ee90',
  lightpink: '#ffb6c1',
  lightsalmon: '#ffa07a',
  lightseagreen: '#20b2aa',
  lightskyblue: '#87cefa',
  lightslategray: '#778899',
  lightsteelblue: '#b0c4de',
  lightyellow: '#ffffe0',
  lime: '#00ff00',
  limegreen: '#32cd32',
  linen: '#faf0e6',
  magenta: '#ff00ff',
  maroon: '#800000',
  mediumaquamarine: '#66cdaa',
  mediumblue: '#0000cd',
  mediumorchid: '#ba55d3',
  mediumpurple: '#9370d8',
  mediumseagreen: '#3cb371',
  mediumslateblue: '#7b68ee',
  mediumspringgreen: '#00fa9a',
  mediumturquoise: '#48d1cc',
  mediumvioletred: '#c71585',
  midnightblue: '#191970',
  mintcream: '#f5fffa',
  mistyrose: '#ffe4e1',
  moccasin: '#ffe4b5',
  navajowhite: '#ffdead',
  navy: '#000080',
  oldlace: '#fdf5e6',
  olive: '#808000',
  olivedrab: '#6b8e23',
  orange: '#ffa500',
  orangered: '#ff4500',
  orchid: '#da70d6',
  palegoldenrod: '#eee8aa',
  palegreen: '#98fb98',
  paleturquoise: '#afeeee',
  palevioletred: '#d87093',
  papayawhip: '#ffefd5',
  peachpuff: '#ffdab9',
  peru: '#cd853f',
  pink: '#ffc0cb',
  plum: '#dda0dd',
  powderblue: '#b0e0e6',
  purple: '#800080',
  rebeccapurple: '#663399',
  red: '#ff0000',
  rosybrown: '#bc8f8f',
  royalblue: '#4169e1',
  saddlebrown: '#8b4513',
  salmon: '#fa8072',
  sandybrown: '#f4a460',
  seagreen: '#2e8b57',
  seashell: '#fff5ee',
  sienna: '#a0522d',
  silver: '#c0c0c0',
  skyblue: '#87ceeb',
  slateblue: '#6a5acd',
  slategray: '#708090',
  snow: '#fffafa',
  springgreen: '#00ff7f',
  steelblue: '#4682b4',
  tan: '#d2b48c',
  teal: '#008080',
  thistle: '#d8bfd8',
  tomato: '#ff6347',
  turquoise: '#40e0d0',
  violet: '#ee82ee',
  wheat: '#f5deb3',
  white: '#ffffff',
  whitesmoke: '#f5f5f5',
  yellow: '#ffff00',
  yellowgreen: '#9acd32',
  transparent: '#ffffff00',
};

const getHex = (color) => {
  if (!color) return null;
  if (color.startsWith('#')) return color;
  if (color.includes('transparent')) return nameColorHex.transparent;
  if (typeof nameColorHex[color.toLowerCase()] !== 'undefined') {
    return nameColorHex[color.toLowerCase()];
  }

  color = color.startsWith('var') ? color : `var(${color})`;

  const globalColorKey = color.substring(4, color.length - 1);
  if (typeof window === 'undefined') {
    return varColorHex[globalColorKey];
  }
  return getComputedStyle(document.documentElement).getPropertyValue(globalColorKey);
};

const getColor = (color) => {
  if (!color) return null;

  const colorKey = Object.keys(colorTypeHelper).find(
    (key) => key.toLowerCase().includes(color.toLowerCase()) && colorTypeHelper[key],
  );

  return colorTypeHelper[colorKey] ? `var(${colorTypeHelper[colorKey]})` : color;
};

const useIsColorDark = (colorValue) => {
  if (isNil(colorValue)) {
    return null;
  }

  let color = colorValue.trim().toLowerCase().replace(/ /g, '');
  const match = color.match(/var\(--(\w+)\)/);
  color = match?.[1] || color;
  let r;
  let g;
  let b;
  color = getHex(color) || color;

  if (color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    [r, g, b] = color;
  } else {
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  return !(hsp > 160);
};

const useIsImageDark = (imagePath) => {
  const [isImageDark, setIsImageDark] = useState(null);

  useEffect(() => {
    if (imagePath) {
      const getImageBrightness = (imageElement) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = imageElement.width;
        canvas.height = imageElement.height;

        ctx.drawImage(imageElement, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const { data } = imageData;

        let brightnessSum = 0;

        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];

          const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
          brightnessSum += brightness;
        }

        const averageBrightness = brightnessSum / (data.length / 4);
        return averageBrightness;
      };

      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        const imageBrightness = getImageBrightness(image);

        setIsImageDark(imageBrightness < 0.5);
      };
      image.src = imagePath;
    }
  }, [imagePath]);

  return isImageDark;
};

const useCheckHeroDark = (hero) => {
  const heroBackgroundImage =
    hero?.backgroundImage?.file?.url ||
    hero?.backgroundImage?.image?.file?.url ||
    hero?.image?.file?.url ||
    hero?.styles?.backgroundImage?.image?.file?.url ||
    hero?.template?.styles?.backgroundImage?.image?.file?.url;

  const heroBackgroundColor =
    (hero?.background && (backgroundColorHex[hero.background] || hero.background)) ||
    (hero?.styles && backgroundColorHex[hero?.styles?.backgroundColor]) ||
    (hero?.template?.styles?.backgroundColor &&
      backgroundColorHex[hero?.template?.styles?.backgroundColor]);

  const imageDark = useIsImageDark(heroBackgroundImage);
  const colorDark = useIsColorDark(heroBackgroundColor);

  if (
    hero?.textColor ||
    hero?.heroLogoColor ||
    hero?.section?.heroLogoColor ||
    hero?.section?.theme ||
    hero?.template?.theme
  ) {
    return hero?.textColor === 'White' ||
      hero?.heroLogoColor === 'White' ||
      hero?.section?.heroLogoColor === 'White' ||
      hero?.section?.theme === 'Light' ||
      hero?.template?.theme === 'Light'
      ? true
      : hero?.textColor === 'Black' || hero?.heroLogoColor === 'Black'
      ? false
      : null;
  }

  return imageDark || colorDark;
};

export { colorTypeHelper, getColor, useIsColorDark, useIsImageDark, useCheckHeroDark };
