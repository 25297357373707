const pollForDefinition = (scope, varname, callback) => {
  if (typeof scope[varname] !== 'undefined') {
    callback();
    return;
  }
  const interval = setInterval(() => {
    if (typeof scope[varname] !== 'undefined') {
      clearInterval(interval);
      callback();
    }
  }, 250);
};

export default pollForDefinition;
