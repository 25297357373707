import Cookies from 'js-cookie';
import queryString from 'query-string';
import Url from 'url-parse';

const domain = 'servicetitan.com';

export const isInternalLink = (url) => {
  if (!url || typeof url !== 'string') return false;
  if (url[0] === '/' || url[0] === '#') return true;
  if (url.startsWith(`www.${domain}`) || url.startsWith(domain)) return true;

  if ([`www.${domain}`, domain].includes(new Url(url).hostname)) return true;

  if (url.match(new RegExp(`[a-zA-Z]*.${domain}`, 'gi'))) return false;

  if (typeof window !== 'undefined') {
    const formatHost = () => {
      const host = window.location.host.split('www.');

      return host.length > 1 ? host[1] : host[0];
    };

    return url.includes(formatHost());
  }

  return false;
};

export const formatInternalLink = (url) => {
  if (url.startsWith('#')) {
    return url;
  }
  const formattedUrl = new Url(url);
  return formattedUrl.pathname + formattedUrl.query + formattedUrl.hash;
};

export const convertToolResultToParams = (values) => {
  return values.map((x, index) => `v${index}=${x}`).join('&');
};

export const convertParamsToToolResult = (initResult, params) => {
  const result = Object.keys(initResult).reduce(
    (acc, cur, index) => ({
      ...acc,
      [cur]: params[`v${index}`] || null,
    }),
    {},
  );

  return result;
};

export const isProd =
  typeof window !== 'undefined' && window?.location?.href.toLowerCase().includes(domain);

export const queryStringToCookies = (queryParams) => {
  const params = queryParams || queryString.parse(window.location.search);
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      Cookies.set(key, params[key]);
    }
  });
};

export const removeSlash = (slug) =>
  slug && slug.startsWith('/') ? slug.substr(1, slug.length) : slug;

export const removeLastSlash = (slug) => (slug && slug.endsWith('/') ? slug.slice(0, -1) : slug);

export const splashCheck = (slug) => slug && slug.replace(/\/\//g, '/').trim();

export const isInPath = (url, path, excludes = []) => {
  const url1 = new Url(url).pathname;
  const url2 = new Url(path).pathname;

  if (excludes.includes(url1)) {
    return url1 === url2;
  }

  return url1.startsWith(url2);
};
