/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Modal from 'components/Core/Modal';
import { FormTitleStyles } from '../../../Form/styles';

export const OptionsContainer = styled.div`
  ${(props) => (!props.visible ? 'display:none' : '')};
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: white;
  position: absolute;
  z-index: var(--z-modal-select);
  top: 0;
  left: 0;
  padding: 33px 30px 0;
  overflow: auto;

  @media (max-width: 575px) {
    position: fixed;
    bottom: 0;
  }

  .subtitle {
    margin: 0 auto 20px auto;
    text-align: center;
    max-width: 400px;

    @media (max-width: 991px) {
      margin: 0 auto 20px auto;
    }

    @media (max-width: 767px) {
      margin: 10px auto 20px auto;
    }
  }

  .footer {
    margin: 0;
    padding: 0 0 0 10px;

    @media (max-width: 767px) {
      position: absolute;
      bottom: 0;
      padding: 0 20px 20px 10px;
    }
    @media (max-height: 495px) {
      position: static;
    }
  }

  &:has(.subtitle) {
    @media (max-height: 600px) {
      .footer {
        position: static;
      }
    }
  }

  &:has(.footer) {
    .radio-field--group {
      margin-bottom: 20px;
      display: inline-block;
    }
  }

  .radio-field--group .radio-field-wrapper {
    height: auto;
    .radio-div {
      margin: 10px;
      height: 60px;
      flex: 40%;
      label {
        color: var(--darkest);
        font-weight: normal;
        font-size: var(--font-size-20);
        @media (max-width: 767px) {
          font-size: var(--font-size-16);
          background-color: transparent;
        }
      }
    }
  }

  .slick-prev {
    position: absolute;
    z-index: 1;
    top: 15px;
    left: 5px;
    padding: 0 !important;

    &:before {
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 9px;
      content: '';
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);

      @media (max-width: 767px) {
        padding: 7px;
      }
    }
  }
`;

export const OptionsModal = styled(Modal)`
  .modal-container {
    width: 600px;
  }

  .options-container {
    position: relative;
  }

  @media (max-width: 575px) {
    .modal-container {
      width: 100vw;
      max-width: 100%;
      border-radius: 0;
    }
  }
`;

export const Title = styled.p`
  ${FormTitleStyles}

  @media (min-width: 992px) {
    max-width: 410px;
    margin: 0 auto 24px auto;
  }

  @media (max-width: 767px) {
    margin: 0 0 8px;
  }

  @media (max-width: 575px) {
    max-width: 290px;
    margin: 0 auto 8px auto;
  }
`;

export const SelectContainer = styled.div`
  &.prefilled {
    .prefilledTooltip {
      z-index: var(--z-modal);
      margin-top: 4px;
    }
  }
`;
