import React from 'react';
import brandStaticSprite from '../Brand/Icons/static/brand-static-sprite.svg';

const Icon = ({ id, ...props }) => {
  return (
    <svg {...props}>
      <use href={`${brandStaticSprite}#${id}`} />
    </svg>
  );
};

export default Icon;
