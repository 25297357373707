import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import closeIcon from '../images/close-icon.svg';
import { getMarketoFormId } from 'utils/marketo';
import useBreakpointView from 'utils/useBreakpointView';
import MarketoForm from 'components/Form/MarketoForm';
import useDefaultDemoForm from 'hooks/useDefaultDemoForm';
import { MarketingFormModal } from './styles';

const ModalMarketingForm = ({
  dataPosition,
  formType,
  isModalOpen,
  onModalOk,
  onModalCancel,
  destroyOnClose = false,
  hideCloseIcon,
  onFormSuccess,
  formButtonLabel,
  formTitle,
  termsText,
  redirectUrl,
  closable = true,
  shouldRedirect = true,
  keyboardClose,
  useEmbeddedForm,
  toggleIsModalOpen,
  ...props
}) => {
  const inlineMarketingForm = props.inlineMarketingForm || useDefaultDemoForm();
  const marketoId = parseInt(getMarketoFormId(inlineMarketingForm.marketoForm), 10);
  const isProfilingSubmission = Cookies.get(`marketo_form_${marketoId}_submit`) || false;
  const demoForm5Style = Cookies.get('demo_form_style_5') === 'true';
  const isMobile = useBreakpointView(['xs', 'sm']);
  const [scrollPosition, setScrollPosition] = useState(0);

  if (isModalOpen && isProfilingSubmission !== false) {
    const dataLayerData = JSON.parse(isProfilingSubmission);
    dataLayer.push(dataLayerData);
    onFormSuccess();
  }

  useEffect(() => {
    const container = document.querySelector('#___gatsby');

    if (container) {
      if (isMobile && isModalOpen) {
        const scrollPos = window.pageYOffset;
        container.style.top = `-${scrollPos}px`;
        container.style.position = 'fixed';
        container.style.width = '100%';
        document.body.style.height = 'calc(100vh - env(safe-area-inset-bottom))';
        setScrollPosition(scrollPos);
      }

      return () => {
        container.removeAttribute('style');
      };
    }
  }, [isModalOpen, isMobile]);

  const handleOnModalCancel = () => {
    if (isMobile) {
      const container = document.querySelector('#___gatsby');

      container?.removeAttribute('style');
      document.body.style.height = null;
      window.scrollTo(0, scrollPosition);
    }

    onModalCancel?.();
  };

  return (
    <MarketingFormModal
      className={`modal-marketing-form--modal ${demoForm5Style ? 'demo-form-style--5' : ''} ${
        marketoId === 3888 ? 'full-screen-modal pricing-form' : ''
      }`}
      open={isModalOpen && !isProfilingSubmission}
      handleClose={handleOnModalCancel}
    >
      <div className="modal-marketing-form--container">
        <div className="modal-marketing-form--form-container">
          {!hideCloseIcon && (
            <img className="close-button" onClick={() => handleOnModalCancel()} src={closeIcon} />
          )}
          <MarketoForm
            data-testid={`modal-marketing-form-${marketoId}`}
            dataPosition={dataPosition || 'Modal Form'}
            formType={formType}
            inlineMarketingForm={inlineMarketingForm}
            buttonLabel={formButtonLabel}
            onSuccess={onFormSuccess}
            shouldRedirect={shouldRedirect}
            closeModal={handleOnModalCancel}
            titleSize="30px"
            title={formTitle}
            termsText={termsText}
            redirectUrl={redirectUrl}
            useEmbeddedForm={useEmbeddedForm}
            demoForm5Style={demoForm5Style}
            isModalForm
          />
        </div>
      </div>
    </MarketingFormModal>
  );
};

export default ModalMarketingForm;
