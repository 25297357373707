import styled, { css } from 'styled-components';

const MarketoEmbeddedContent = styled.div`
  width: 100%;
  ${(props) => (props.modalMode ? '0px' : '33px 40px')};

  .marketo-embedded--container {
    background: white;
    padding: 33px 30px;
    box-shadow: rgba(11, 26, 33, 0.15) 0px 30px 60px;
    margin: 0 auto;

    .marketo-embedded--form-title {
      color: black;
      font-size: var(--font-size-28);
      margin: 0 0 30px 0;
    }

    form.mktoForm {
      font-family: var(--font-family-body) !important;
      width: 100% !important;

      .mktoFormRow {
        width: 100%;

        .mktoFormCol {
          width: 100%;
          margin-bottom: 14px !important;
          min-height: 0;
        }

        .mktoFieldWrap {
          width: 100%;
        }

        .mktoGutter,
        .mktoOffset {
          height: 0;
        }

        .mktoHtmlText {
          width: 100% !important;
        }

        .mktoLabel {
          width: 100% !important;
          font-family: var(--font-family-heading);
          font-size: var(--font-size-20) !important;
          line-height: var(--line-height-120);
          font-weight: var(--font-weight-700) !important;
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          margin-bottom: 6px;
          padding-top: 0;
          text-transform: capitalize;
          letter-spacing: 0;
          .mktoAsterix {
            flex: auto;
          }
        }

        p.formSubHead {
          width: 100% !important;
          font-size: var(--font-size-28);
          font-weight: var(--font-weight-600);
          line-height: 43px;
          letter-spacing: var(--letter-spacing--0-04);
          color: var(--darkest);
        }

        .mktoField.mktoTextField,
        .mktoField.mktoEmailField,
        .mktoField.mktoTelField,
        .mktoField.mktoNumberField {
          height: 58px !important;
          border-radius: 0px !important;
          font-size: var(--font-size-20) !important;
          font-family: var(--font-family-heading) !important;
          font-weight: var(--font-weight-500) !important;
          border: 1px solid var(--grey-4);
          width: 100% !important;
          padding: 21px 23px;
          letter-spacing: 0px !important;

          &:focus {
            border: 1px solid var(--titan-blue-3);
            outline: none;
            box-shadow: none;
          }
        }

        .mktoRadioList {
          width: 100% !important;
          display: flex;
          height: 58px;
          padding: 0;

          input.mktoField {
            position: absolute;
            z-index: -1;
            opacity: 0;
          }

          & > label {
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: var(--font-size-16);
            font-family: var(--font-family-heading) !important;
            font-weight: var(--font-weight-600);
            position: relative;
            padding: 11px 10px;
            line-height: 22px;
            cursor: pointer;
            border: 1px solid var(--grey-4);
            transition: 0.3s;
            text-align: center;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--grey-8);

            &:hover {
              background-color: rgba(0, 118, 173, 0.05);
            }

            &:before {
              visibility: hidden;
            }
          }

          input:checked + label {
            border: 3px solid var(--titan-blue-3);
            border-color: var(--titan-blue-3);
            color: var(--titan-blue-6);
          }
        }

        select.mktoField {
          font-size: var(--font-size-20) !important;
          font-family: var(--font-family-heading) !important;
          font-weight: var(--font-weight-500) !important;
          width: 100% !important;
          height: 58px;
          border-radius: 0;
          padding: 0 23px;
          border: 1px solid var(--grey-4);
          background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+)
            no-repeat;
          background-position: right 15px top 24px;
          background-size: 14px 10px;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;

          &:focus {
            border: 1px solid var(--titan-blue-3);
            outline: none;
            box-shadow: none;
          }
        }

        select[multiple='multiple'].mktoField {
          height: auto;
        }

        .mktoCheckboxList {
          width: auto !important;

          & > input {
            margin-top: 1px;
            width: 16px;
            height: 16px;
          }

          & > label {
            font-size: var(--font-size-18);
          }
        }
      }

      .mktoButtonRow {
        width: 100%;
        margin-top: 10px !important;

        .mktoButtonWrap {
          width: 100%;
          margin: 0 auto !important;
          button {
            background-color: var(--titan-blue-3) !important;
          }
        }

        .mktoButton {
          font-weight: var(--font-weight-700);
          font-size: var(--font-size-20) !important;
          font-family: var(--font-family-heading) !important;
          line-height: 32px !important;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 0 !important;
          margin: 0;
          cursor: pointer;
          width: 100% !important;
          padding: 16px 24px !important;
          height: auto;
          box-shadow: none;
          color: var(--white) !important;
          background-color: var(--critical-3) !important;
          transition: background 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) !important;
          background-image: none !important;

          &:hover {
            background-color: var(--critical-4);
            color: var(--white);
            border: none;
          }
        }
      }
    }

    &.thanks-container {
      padding: 55px 40px;
      text-align: center;

      h2 {
        font-size: var(--font-size-44);
      }

      p {
        font-size: var(--font-size-28);
        margin: 40px 0 0;
      }

      button,
      a {
        min-width: 200px;
        margin-top: 40px;
      }
    }
    .mktoForm .mktoError {
      width: 100%;
      display: inline-block;
      position: initial;
      z-index: 99;
      color: var(--critical-3);
      font-family: var(--font-family-body) !important;
      left: 0;
      bottom: -26px !important;
    }
    .mktoForm .mktoError .mktoErrorMsg {
      margin-top: 0px;
      display: flex !important;
      max-width: fit-content !important;
      border-radius: 0px !important;
      text-shadow: none;
      box-shadow: none;
      background-color: var(--critical-3);
      background-image: none;
      border: none;
    }
    .mktoErrorArrowWrap {
      display: none;
      position: initial;
    }
  }

  &.blog-detail--marketo-form-embed,
  &.rich-text-content--marketo-form-embed {
    .marketo-embedded--container {
      form.mktoForm {
        background: white;
        padding: ${(props) => (props.modalMode ? '33px 30px' : '0px')};

        .mktoFormRow .mktoFieldWrap {
          margin-bottom: 0 !important;
        }

        .mktoGutter {
          height: 0 !important;
        }
      }
    }
  }

  .marketo-embedded--container {
    width: ${(props) => (props.modalMode ? '520px' : 'auto')};
  }

  @media (max-width: 575px) {
    .marketo-embedded--container {
      width: ${(props) => (props.modalMode ? 'calc(100% - 40px)' : 'auto')};
    }
  }

  .close-button {
    position: absolute;
    right: 40px;
    top: 70px;
  }

  &.blog-detail--marketo-form-embed {
    .marketo-embedded--container {
      background: var(--titan-blue-3);
    }
  }

  &.rich-text-content--marketo-form-embed {
    .marketo-embedded--container {
      box-shadow: none;
    }
  }

  ${(props) => props.theme === 'blog' && blogTheme}

  @media only screen and (max-width: 479px) {
    .marketo-embedded--container {
      padding: 33px 20px;

      form.mktoForm {
        padding: 0;
      }
    }
  }
`;

const blogTheme = css`
  .marketo-embedded--container {
    background: var(--titan-blue-3);

    .marketo-embedded--form-title {
      color: white;
      font-size: var(--font-size-35);
      margin: 0 0 24px 0;
    }

    form.mktoForm {
      background: white;
      padding: 33px 26px;
      font-size: var(--font-size-13);
    }
  }
`;

export { MarketoEmbeddedContent };
