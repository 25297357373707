import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { WithFormControl } from 'components/Core/FormControl';
import { NumberInputFieldContainer } from './styles';

const NumberInputField = (props) => {
  const {
    id,
    label,
    min = 0,
    max,
    step = 1,
    nameType,
    values,
    value,
    formValid,
    formErrors,
    className,
    handleFocus,
    handleBlur,
    handleChange,
    handleKeyPress,
  } = props;
  const [inputValue, setInputValue] = useState(value || values[id]);

  useEffect(() => {
    setInputValue(value || values[id]);
  }, [value, values]);

  const onHandleFocus = () => {
    if (handleFocus !== undefined) handleFocus(id);
  };

  const onHandleBlur = (event) => {
    let { value: tmpValue } = event.target;

    if (tmpValue === '#' || tmpValue === '') return;
    tmpValue = tmpValue.length === 0 ? min || 0 : tmpValue;

    if (handleChange !== undefined) {
      tmpValue = parseFloat(tmpValue, 10);
      tmpValue = tmpValue < min ? min : tmpValue;
      if (max) {
        tmpValue = tmpValue > max ? max : tmpValue;
      }
      handleChange(id, tmpValue);
    }

    if (handleBlur !== undefined) {
      handleBlur(id, tmpValue);
    }
  };

  const onHandleChange = (event) => {
    // Remove any characters that are not digits or a single decimal point or minus sign
    // Allow digits, a single decimal point, and a minus sign
    // Remove all minus signs except at the beginning
    // Allow only one decimal point
    const cleanedValue = event.target.value
      .replace(/[^0-9.-]/g, '')
      .replace(/(?!^)-/g, '')
      .replace(/(\..*)\./g, '$1');
    // Update the input value state
    setInputValue(cleanedValue);

    // Convert the cleaned value to a float
    const floatValue = parseFloat(cleanedValue);
    // Call the handleChange function with the correct float value or the minimum value if NaN
    if (handleChange !== undefined) {
      handleChange(id, Number.isNaN(floatValue) ? min : floatValue);
    }
  };

  const onHandleKeyPress = (event) => {
    if (handleKeyPress !== undefined) handleKeyPress(event);
  };

  const onHandleButtonClick = (num) => {
    if (handleChange !== undefined) {
      let tmpValue;
      if (value === '#' || value === '') {
        tmpValue = 0;
      } else {
        tmpValue =
          value !== null && value !== undefined
            ? value
            : values && values[id] !== undefined
            ? values[id]
            : min;
        tmpValue += num * step;
        // To round a number to a specified number of decimal places
        const factor = 10 ** 2;
        tmpValue = Math.round(tmpValue * factor) / factor; // Round to 2 decimal places
        tmpValue = tmpValue < min ? min : tmpValue;
        if (max) {
          tmpValue = tmpValue > max ? max : tmpValue;
        }
      }
      handleChange(id, tmpValue);
    }
  };

  return (
    <NumberInputFieldContainer
      className={`text-field-wrapper 
        ${className || ''} 
        ${formValid[id] === -1 ? 'input-failed-value' : 'input-success-value'}`}
    >
      {label && <p className="number-input-field--label font-heading-md">{label}</p>}
      <div className="number-input-field--wrapper">
        <div className="number-input-field--minus" onClick={() => onHandleButtonClick(-1)}>
          -
        </div>
        <div className="number-input-field--input">
          <TextField
            id={id}
            name={id}
            data-testid={id && `textfield-${id}`}
            className="text-field-input"
            defaultValue={min}
            value={inputValue}
            onBlur={onHandleBlur}
            onChange={onHandleChange}
            onFocus={onHandleFocus}
            onKeyPress={onHandleKeyPress}
            variant="outlined"
            label={
              (!label && nameType && (formValid[id] === -1 ? formErrors[id] : nameType)) || null
            }
            error={formValid[id] === -1}
            fullWidth
          />
        </div>
        <div className="number-input-field--plus" onClick={() => onHandleButtonClick(1)}>
          +
        </div>
      </div>
    </NumberInputFieldContainer>
  );
};

export default WithFormControl(NumberInputField);
