import React from 'react';
import { CheckboxesFieldWrapper, CheckboxesTitle } from './styles';

const Checkboxes = (props) => {
  const {
    id,
    nameType,
    formValid,
    formErrors,
    handleFocus: onFocus,
    handleChange: onChange,
    values = {},
    options,
    dataCy,
  } = props;

  let { value } = props;
  value = value || (values && values[id]);

  const handleFocus = (checkBoxValue) => {
    if (onFocus !== undefined) {
      if (value?.includes(checkBoxValue)) {
        value = value?.split(', ').filter((v) => v !== checkBoxValue);
      } else {
        value = value?.split(', ') || [];
        value.push(checkBoxValue);
      }
      onFocus(id, value.length > 1 ? value.join(', ') : value[0]);
    }
  };

  const handleChange = (event) => {
    if (onChange !== undefined) onChange(id, event.target.value);
  };

  return (
    <>
      <CheckboxesTitle htmlFor={id} className="checkboxes-field--title">
        {nameType}
      </CheckboxesTitle>
      <CheckboxesFieldWrapper
        formValid={formValid}
        id={id}
        className={`checkboxes-field-wrapper${
          formValid[id] === -1 ? ' checkboxes-field-error' : ''
        }`}
      >
        {options.map((item, index) => {
          const checked = value?.split(', ')?.includes(item.value);
          const labelId = `${id}-${Math.floor(Math.random() * 100000000)}`;
          return (
            <div className={`checkboxes-div${checked ? ' selected' : ''}`} key={index}>
              <input
                name={id}
                id={labelId}
                type="checkbox"
                value={item.value}
                onChange={handleChange}
                checked={checked}
              />
              <label
                htmlFor={labelId}
                onClick={() => handleFocus(item.value)}
                data-cy={dataCy && item.label}
              >
                {item.label}
              </label>
            </div>
          );
        })}
      </CheckboxesFieldWrapper>
    </>
  );
};

export default Checkboxes;
