const getMaxSizingStyles = (image, styles) => {
  const { height, width } = image?.file?.details?.image || {};
  const { maxHeightDesktop, maxHeightMobile, maxWidthDesktop, maxWidthMobile } = styles || {};
  const isPortrait = height > width;

  if (isPortrait) {
    return `
        height: ${maxHeightDesktop || maxHeightMobile};

        .gatsby-image-wrapper {
          max-height: 100%;

          img {
            object-fit: contain !important;
          }
        }

        @media (max-width: 767px) {
            height: ${maxHeightMobile}; 
        }
    `;
  } else {
    return `
    width: ${maxWidthDesktop || maxWidthMobile};

    .gatsby-image-wrapper {
      img {
        object-fit: contain !important;
      }
    }

    @media (max-width: 767px) {
        width: ${maxWidthMobile}; 
    }
`;
  }
};

export default getMaxSizingStyles;
