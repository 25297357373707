import React, { useState, useEffect } from 'react';

const HeaderNavContext = React.createContext({});

export default HeaderNavContext;

export const HeaderNavContextOverlay = ({ children, ...otherProps }) => {
  const [openedMobileMenuInfo, setOpenedMobileMenuInfo] = useState(null);
  const [showSimpleOrMain, setShowSimpleOrMain] = useState(false); // true: Simple, false: Main
  const [selectedContentType, setSelectedContentType] = useState('Commercial');
  const { location } = otherProps;

  useEffect(() => {
    if (!location?.state?.redirectedFromHeaderMenu) {
      setOpenedMobileMenuInfo({
        ...openedMobileMenuInfo,
        menuLabel: location?.state?.redirectedFromHeaderMenu,
      });
    }
  }, [location]);

  return (
    <HeaderNavContext.Provider
      value={{
        openedMobileMenuInfo,
        setOpenedMobileMenuInfo,
        showSimpleOrMain,
        setShowSimpleOrMain,
        selectedContentType,
        setSelectedContentType,
      }}
    >
      {children}
    </HeaderNavContext.Provider>
  );
};
