import { graphql, useStaticQuery } from 'gatsby';

const useDefaultDemoForm = () => {
  const { contentfulComponentCollection } = useStaticQuery(graphql`
    query {
      contentfulComponentCollection(contentful_id: { eq: "1PUv1YU6Z8MLE26vKIwNVL" }) {
        components {
          ...InlineMarketingForm
        }
      }
    }
  `);

  return contentfulComponentCollection.components[0];
};

export default useDefaultDemoForm;
