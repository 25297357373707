import { useEffect } from 'react';

const useGlowPulse = (container) => {
  useEffect(() => {
    if (container.current) {
      const handlePulse = () => {
        const muiFields = [...(container.current.querySelectorAll('.MuiFilledInput-root') || [])];
        const radioFields = [...(container.current.querySelectorAll('.radio-field-wrapper') || [])];
        [...muiFields, ...radioFields].forEach((f) => {
          f.classList.remove('pulse');
          setTimeout(() => {
            f.classList.add('pulse');
          }, 50);
        });
      };

      const btns = container.current.querySelectorAll('[type="submit"]');
      if (container.current && btns.length > 0) {
        btns.forEach((btn) => {
          btn.addEventListener('touchend', handlePulse);
          btn.addEventListener('click', handlePulse);
        });
      }

      return () => {
        btns.forEach((btn) => {
          btn.removeEventListener('click', handlePulse);
          btn.removeEventListener('touchend', handlePulse);
        });
      };
    }
  }, [container]);
};

export default useGlowPulse;
