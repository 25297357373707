import React from 'react';
import RadioGroup from 'components/Core/RadioGroup';
import Checkboxes from 'components/Core/Checkboxes';
import Select from 'components/Core/Select';
import IndustrySelector from 'components/Core/Select/IndustrySelector';
import SelectEnhanced from 'components/Core/Select/SelectEnhanced';
import NumberInputField from 'components/Core/NumberInputField';
import TextField from 'components/Core/TextField';
import { WithFormControl } from 'components/Core/FormControl';
import useIndustries from 'hooks/useIndustries';
import { technicianOptions } from 'components/Form/utils/generalFormData';

const DynamicFormField = ({
  dataCy,
  field,
  value,
  formErrors,
  onHandleBlur,
  onHandleChange,
  onHandleFocus,
  useEnhancedSelect,
  formControlProps,
  isModalForm,
  ...otherProps
}) => {
  const formValid = {};
  formValid[field.id] =
    formErrors[field.id] && formErrors[field.id].length > 0 ? -1 : value ? 1 : 0;

  if (
    field.dataType === 'text' ||
    field.dataType === 'telephone' ||
    field.dataType === 'email' ||
    field.dataType === 'textArea'
  ) {
    return (
      <TextField
        id={field.id}
        type={field.dataType}
        format={field.dataType === 'telephone' ? '(###) ###-####' : ''}
        nameType={field.label}
        placeholderText={field.hintText || field.label}
        value={value}
        formValid={formValid}
        formErrors={formErrors}
        handleBlur={onHandleBlur}
        handleFocus={onHandleFocus}
        handleChange={(id, val) => onHandleChange(id, val, 'TextField', true)}
        {...otherProps}
      />
    );
  }

  if (field.dataType === 'select') {
    const { values, multiSelect } = field?.fieldMetaData;
    const { $industryInclusionList, $industryExclusionList } = otherProps || {};
    if (field.id === 'Primary_Industry__c') {
      const { industryList: dropdownIndustry, otherIndustryList } = useIndustries(
        $industryInclusionList,
        $industryExclusionList,
      );

      if (values.length <= 1) {
        values.length = 0;
        values.push(...dropdownIndustry);
      }

      const newOptions =
        $industryInclusionList || $industryExclusionList ? dropdownIndustry : values;

      if (useEnhancedSelect) {
        return (
          <IndustrySelector
            dataCy={dataCy}
            id={field.id}
            otherId="industryTestVerticals"
            nameType={field.label}
            formValid={formValid}
            label="What is your company's main industry?"
            placeholder={`Select ${field.label}`}
            otherOptions={otherIndustryList}
            options={newOptions}
            values={newOptions}
            value={value}
            handleChange={(id, val) => onHandleChange(id, val, 'Select')}
            isModalForm={isModalForm}
            {...otherProps}
          />
        );
      }
    } else if (useEnhancedSelect && field.id === 'industryTestVerticals') {
      return <></>;
    } else if (field.id === 'No_of_Technicians__c') {
      if (values.length <= 1) {
        values.length = 0;
        values.push(...technicianOptions);
      }

      const { industrySpecificTechnicianName: techLabel, label: industryLabel } =
        otherProps.$allIndustriesList.find(
          (x) => x.value === otherProps.values.Primary_Industry__c,
        ) || {};

      const defaultSingular = 'technician';
      const defaultPlural = `${defaultSingular}s`;

      const displayLabels = {
        label: `How many ${defaultPlural} work at your company?`,
        instructions: field.instructions,
        placeholder: field.label,
        footerText: undefined,
        values,
      };

      if (techLabel) {
        const plural = techLabel.split(',')?.[1].trim();
        const singular = techLabel.split(',')[0].trim();
        displayLabels.placeholder = displayLabels.placeholder.replace(
          new RegExp('techs', 'ig'),
          plural
            .split(' ')
            .map((x) => `${x.charAt(0).toUpperCase()}${x.slice(1)}`)
            .join(' '),
        );
        displayLabels.label = displayLabels.label.replace(defaultPlural, `${plural}*`);

        displayLabels.values = values.map((x) => ({
          ...x,
          label: x.label.replace('techs', plural).replace('tech', singular),
        }));

        displayLabels.footerText = `*The ${industryLabel} equivalent of ${defaultSingular} is a ${singular}.`;
        displayLabels.instructions = displayLabels?.instructions?.replace('techs', plural);
      }

      if (useEnhancedSelect) {
        return (
          <SelectEnhanced
            dataCy={dataCy}
            id={field.id}
            nameType={displayLabels.placeholder}
            formValid={formValid}
            label={displayLabels.label}
            placeholder={!multiSelect && `Select ${displayLabels.placeholder}`}
            options={displayLabels.values.filter((x) => x.label !== 'Select...')}
            handleChange={(id, val) => onHandleChange(id, val, 'Select')}
            value={value}
            values={displayLabels.values}
            multiple={multiSelect}
            getPopupContainer={(trigger) => trigger.closest('.marketo-form') || document.body}
            isModalForm={isModalForm}
            footerText={displayLabels.footerText}
            subtitle={displayLabels.instructions}
            {...otherProps}
          />
        );
      }
    }

    return (
      <Select
        id={field.id}
        nameType={field.label}
        formValid={formValid}
        label={multiSelect && field.label}
        placeholder={!multiSelect && `Select ${field.label}`}
        options={values}
        handleChange={(id, val) => onHandleChange(id, val, 'Select')}
        value={value}
        values={values}
        multiple={multiSelect}
        {...otherProps}
      />
    );
  }

  if (field.dataType === 'radioButtons' || field.dataType === 'checkbox') {
    return (
      <RadioGroup
        dataCy={dataCy}
        id={field.id}
        nameType={field.label}
        options={
          field?.fieldMetaData?.values || [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ]
        }
        value={value}
        formValid={formValid}
        formErrors={formErrors}
        handleChange={(id, val) => onHandleChange(id, val, 'RadioGroup')}
        handleFocus={onHandleFocus}
        tooltipText={field?.instructions}
        {...otherProps}
      />
    );
  }

  if (field.dataType === 'checkboxes') {
    return (
      <Checkboxes
        id={field.id}
        nameType={field.label}
        options={field?.fieldMetaData?.values}
        value={value}
        formValid={formValid}
        formErrors={formErrors}
        handleChange={(id, val) => onHandleChange(id, val, 'Checkboxes')}
        handleFocus={onHandleFocus}
        {...otherProps}
      />
    );
  }

  if (field.dataType === 'number') {
    return (
      <NumberInputField
        id={field.id}
        nameType={field.label.startsWith('# of') ? field.label : `# of ${field.label}`}
        min={field?.fieldMetaData?.minValue || 0}
        max={field?.fieldMetaData?.maxValue || 100}
        value={
          value !== undefined && value !== null && value !== NaN && value !== ''
            ? parseFloat(value)
            : '#'
        }
        formValid={formValid}
        formErrors={formErrors}
        handleChange={(id, val) => onHandleChange(id, val, 'NumberInputField')}
        {...otherProps}
      />
    );
  }

  return <></>;
};

export default WithFormControl(DynamicFormField);
