import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import useBreakpointView from 'utils/useBreakpointView';
import closeIcon from './images/close-icon.svg';

import './modal.less';
import { StyledModal } from './styles';

const Modal = ({ children, handleClose, hideCloseIcon, scroll, onBackdropClick, ...props }) => {
  const isXsScreen = useBreakpointView(['xs']);

  return (
    <StyledModal
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          onBackdropClick && onBackdropClick();
        }
        if (reason === 'escapeKeyDown') {
          handleClose && handleClose();
        }
      }}
      scroll={scroll || isXsScreen ? 'paper' : 'body'}
      fullScreen={isXsScreen}
      {...props}
    >
      <div className="modal-container" data-optimizely="modal-container">
        {!hideCloseIcon && <img className="close" onClick={handleClose} src={closeIcon} />}
        {children}
      </div>
    </StyledModal>
  );
};

export default Modal;
