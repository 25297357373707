import React, { createContext, useState } from 'react';
import Cookies from 'js-cookie';

const EditableWidgetContext = createContext();

export default EditableWidgetContext;

export const EditableProvider = ({ children }) => {
  const [editable, setEditable] = useState(false);

  const updateEditable = (value) => {
    setEditable(value);
    Cookies.set('editable', value);
  };

  return (
    <EditableWidgetContext.Provider value={{ editable, updateEditable }}>
      {children}
    </EditableWidgetContext.Provider>
  );
};
