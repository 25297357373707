import React, { forwardRef, useRef, useContext } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { isInternalLink, formatInternalLink } from 'utils/urlHelper';
import SweetScrollContext from 'utils/SweetScrollContext';

const HyperLink = forwardRef(
  ({ children, className, dataPosition, onClick, href, target, rel, ...otherProps }, ref) => {
    if (!ref) ref = useRef();

    const { updateScrollOption } = useContext(SweetScrollContext);
    const isLinkInternal = isInternalLink(href);
    const url = isLinkInternal ? formatInternalLink(href) : href;
    const { ctaPosition, ctaText, shadow, ...linkProps } = otherProps;
    href = href || '';

    const onClickHandler = (event) => {
      updateScrollOption?.();
      if (!onClick) {
        dataLayer.push({
          event: 'cta clicked',
          cta_text: ctaText || ref?.current?.textContent || 'none',
          cta_position: ctaPosition || linkProps['data-position'] || 'none',
          cta_url: url,
        });
        return;
      }
      onClick(event);
    };

    if (target !== '_blank' && isLinkInternal && !url?.startsWith('#')) {
      return (
        <Link
          data-optimizely="hyperlink"
          data-position={dataPosition || linkProps['data-position']}
          className={className}
          to={url}
          onClick={(event) => onClickHandler(event)}
          target={target}
          rel={rel}
          ref={ref}
          shadow={typeof shadow === 'boolean' ? shadow.toString() : shadow}
          {...linkProps}
        >
          {children}
        </Link>
      );
    }
    return (
      <a
        data-optimizely="hyperlink"
        ref={ref}
        data-position={dataPosition || linkProps['data-position']}
        className={className}
        href={url}
        onClick={(event) => onClickHandler(event)}
        target={url?.startsWith?.('#') ? null : target || '_blank'}
        rel={rel || 'noopener noreferrer'}
        shadow={typeof shadow === 'boolean' ? shadow.toString() : shadow}
        {...linkProps}
      >
        {children}
      </a>
    );
  },
);

HyperLink.propTypes = {
  dataPosition: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

export default HyperLink;
