import styled from 'styled-components';
import { FormTitleStyles } from '../styles';

export const PricingFormContainer = styled.div`
  max-width: 864px;
  min-height: 654px;
  height: 654px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 611px) {
    height: 725px;
  }

  @media (max-width: 575px) {
    min-height: 0;
    height: 100%;
  }

  .dynamic-form--container {
    position: relative;
    height: 100%;
    padding: 0;
  }

  .signup-form--header {
    position: absolute;
    width: 100%;
    padding: 40px 40px 0;
    ${(props) => (props.currentStep < 4 ? 'height: 0' : '')};

    @media (max-width: 575px) {
      padding: 20px 20px 0;
    }

    .radio-field--title,
    .signup-form--title {
      text-align: center;
      margin: 0;
      padding: 0 50px;
      font-size: var(--font-size-h3) !important;
      font-weight: var(--font-weight-900);
      line-height: var(--line-height-110);
      letter-spacing: var(--letter-spacing--0-03);
      color: var(--darkest);

      @media (max-width: 767px) {
        font-size: var(--font-size-25) !important;
        margin-top: -7px;
      }

      @media (max-width: 575px) {
        font-size: var(--font-size-20) !important;
        line-height: var(--line-height-120);
        letter-spacing: var(--letter-spacing--0-01);
        width: 100%;
        padding: 0;
        margin-top: 50px;
      }
    }

    .slick-prev {
      z-index: 1;
      padding: 0;

      &:before {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 7px;
        content: '';
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        opacity: 1;
        transition: border 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.4s;
      }
    }
  }

  .signup-form--form {
    .data-layer-form {
      padding: 40px;
      margin: 0;
      background: ${(props) => (props.currentStep < 4 ? 'var(--white)' : 'transparent')};
      transition: all 0.3s ease-out 0.4s;
      .signup-form--submit-button {
        display: none;
      }
      &.form-step-3 {
        padding: 40px 40px 15px;
        margin: 70px 0 0;

        .signup-form--submit-button {
          display: inline;
          height: 60px;
          margin: 0;
        }
      }

      @media (max-width: 575px) {
        padding: 20px 20px 20px;

        &.form-step-2 {
          min-height: 500px;
        }

        &.form-step-3 {
          padding: 20px 20px 20px;
        }
      }
    }
    .form-step-0 {
      .radio-div {
        label {
          height: 90px !important;
        }
      }
    }

    .form-field {
      text-align: center;

      &.businessFocusResorCommFORMHELPER {
        &::before {
          content: 'What kind of jobs does your company focus on?';
          font-family: var(--font-family);
          font-size: var(--font-size-h3) !important;
          font-weight: var(--font-weight-900);
          line-height: var(--line-height-110);
          letter-spacing: var(--letter-spacing--0-03);
          text-align: center;
          width: 100%;
          display: block;
          color: var(--darkest);
          padding: 0 50px;
          margin-bottom: 40px;
          background: transparent;

          @media (max-width: 767px) {
            font-size: var(--font-size-25) !important;
          }

          @media (max-width: 575px) {
            font-size: var(--font-size-20) !important;
            line-height: var(--line-height-120);
            letter-spacing: var(--letter-spacing--0-01);
            margin-bottom: 20px;
            padding: 0;
            background: transparent;
          }
        }
      }
      margin-bottom: 15px;

      &.signup-form--hp123-item {
        margin: 0;
      }

      .signup-form-button--submit-button {
        display: none;
      }

      .radio-field--group {
        .radio-field--title {
          &:not([for='businessFocusResorCommFORMHELPER']):not(
              [for='businessFocusNewConRemodelSRFORMHELPER']
            ) {
            font-size: var(--font-size-h3) !important;
            font-weight: var(--font-weight-900);
            line-height: var(--line-height-110);
            letter-spacing: var(--letter-spacing--0-03);
            text-align: center;
            width: 100%;
            display: block;
            color: var(--darkest);
            padding: 0 50px;
            margin-bottom: 40px;
            background: transparent;

            @media (max-width: 767px) {
              font-size: var(--font-size-25) !important;
            }

            @media (max-width: 575px) {
              font-size: var(--font-size-20) !important;
              line-height: var(--line-height-120);
              letter-spacing: var(--letter-spacing--0-01);
              margin-bottom: 20px;
              padding: 0;
              background: transparent;
            }
          }
          &[for='businessFocusResorCommFORMHELPER'],
          &[for='businessFocusNewConRemodelSRFORMHELPER'] {
            font-size: var(--font-size-h4) !important;
            color: var(--darkest);

            @media (max-width: 575px) {
              font-size: var(--font-size-h4-mobile) !important;
            }
          }
        }

        .radio-field-wrapper {
          height: auto;
          margin: 0 0 25px;

          @media (max-width: 575px) {
            margin: 0 0 15px;
          }

          .radio-div {
            flex: auto;
            width: 50%;
            margin: 0;

            label {
              border: 1px solid var(--grey-4);
              height: 70px;
              margin: 0;
              font-size: var(--font-size-20);
              font-weight: var(--font-weight-700);
              line-height: var(--line-height-150);
              color: var(--darkest);

              @media (max-width: 767px) {
                font-size: var(--font-size-16);
              }

              @media (max-width: 575px) {
                font-size: var(--font-size-16);
                height: 64px;
              }
            }

            &.selected {
              label {
                color: white;
                background-color: var(--titan-blue-3);
              }
            }

            &:first-child {
              label {
                border-radius: 4px 0px 0px 4px;
                border-right-width: 0;
              }
            }

            &:last-child {
              label {
                border-radius: 0px 4px 4px 0px;
              }
            }
          }

          &#Primary_Industry__c {
            gap: 20px;

            .radio-div {
              width: calc(50% - 20px);

              label {
                ${(props) =>
                  !props.isModalForm &&
                  `
                  background-color: var(--titan-blue-3) !important;
                  border-color: var(--titan-blue-3) !important;
                  color: white;
                    
                  &:hover {
                    background-color: var(--titan-blue-4) !important;
                    border-color: var(--titan-blue-4) !important;
                  }
                `}
                border-radius: 4px;
                border-right-width: 1px;
              }
            }

            @media (max-width: 575px) {
              gap: 8px 0;
              margin: 0;

              .radio-div {
                width: 100%;

                label {
                  font-weight: var(--font-weight-500);
                }
              }
            }
          }

          &#No_of_Technicians__c {
            gap: 20px;

            .radio-div {
              width: calc(33.3% - 20px);

              label {
                border-radius: 4px;
                border-right-width: 1px;
              }
            }

            @media (max-width: 575px) {
              gap: 8px;

              .radio-div {
                width: calc(50% - 16px);
              }
            }
          }

          &#businessFocusResorCommFORMHELPER,
          &#businessFocusNewConRemodelSRFORMHELPER {
            margin-top: 20px;
            @media (max-width: 575px) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .signup-form--footer {
    visibility: hidden;
    opacity: 0;
    height: 0;
    padding: 0 40px;
    transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);

    * {
      color: var(--darkest) !important;
      margin: 0;
    }
    a {
      text-decoration: underline;
    }

    @media (max-width: 575px) {
      padding: 0 20px;

      * {
        font-size: var(--font-size-13);
      }
    }
  }

  &.pricing-form--step-4 {
    .signup-form--footer {
      visibility: visible;
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.4s;
    }
  }

  .signup-form--progress {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;

    & > div {
      width: 45px;
      margin: 0 10px;
      height: 6px;
      background: #ddf8e5;
      border-radius: 6px;

      &:nth-child(-n + ${(props) => props.currentStep - 1 || 0}) {
        background: var(--green);
      }
    }

    @media (max-width: 575px) {
      top: 30px;
      bottom: auto;

      & > div {
        width: 40px;
        margin: 0 8px;
      }
    }
  }

  .modal-close-x {
    display: none;

    @media (max-width: 575px) {
      display: block;
      border: none;
      background: transparent;
      position: absolute;
      right: 17px;
      top: 21px;

      svg path {
        stroke: var(--darkest);
        transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.4s;
      }
    }
  }
`;

export const OtherIndustryContainer = styled.div`
  padding: 40px;
  visibility: hidden;
  opacity: 0;
  background-color: white;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 24px 16px 0;
  }
  @media (max-width: 575px) {
    height: 100%;
    padding: 20px;
  }

  ${(props) =>
    props.visible &&
    `
    visibility: visible;
    opacity: 1;
  `};

  .signup-form--other-industry-header {
    position: relative;

    .slick-prev {
      z-index: 1;
      padding: 0;
      left: 5px;
      top: 15px;
      &:before {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 7px;
        content: '';
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        opacity: 1;
      }
    }

    .signup-form--title {
      ${FormTitleStyles}
      font-size: var(--font-size-h3);

      @media (max-width: 767px) {
        font-size: var(--font-size-25);
      }
      @media (max-width: 575px) {
        font-size: var(--font-size-20);
      }
    }
  }
  @media (max-width: 575px) {
    &.modal-other-industry {
      .signup-form--title {
        padding-top: 42px;
      }
    }
  }

  .signup-form--other-industry-radio {
    height: 457px;
    max-height: 457px;
    position: relative;
    overflow: hidden;
    width: calc(100% + 25px);

    @media (max-width: 575px) {
      width: calc(100% + 18px);
      height: 100%;
      max-height: none;
      margin-bottom: 10px;
    }

    .radio-field-wrapper {
      margin: 0;
      width: 100%;
      height: 457px;
      overflow: overlay;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;

      .radio-div {
        margin: 5px 0;
        max-width: calc(100% - 25px);
        width: 100%;
        max-height: 40px;
        min-height: 40px;

        @media (max-width: 575px) {
          max-width: calc(100% - 18px);
        }

        label {
          font-family: var(--font-family);
          font-size: var(--font-size-16);
          font-weight: var(--font-weight-500);
          line-height: var(--line-height-150);
          padding: 0;
          height: 100%;
          border-radius: 0;
          border: 1px solid var(--grey-4);
          color: var(--darkest);
        }
      }
    }
  }

  .signup-form--other-industry-search {
    margin: 25px 0 9px;
    width: 100%;
    height: 58px;
    position: relative;
    border: 1px solid #d9d9d9;

    input {
      font-family: var(--font-family);
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-20);
      line-height: var(--line-height-150);
      padding: 14px 18px;
      color: var(--darkest);

      &::placeholder {
        color: var(--grey-6);
      }

      @media (max-width: 575px) {
        font-size: var(--font-size-18);
      }
    }

    .signup-form--other-industry-close {
      width: 34px;
      height: 34px;
      position: absolute;
      right: 8px;
      top: 12px;
      padding: 12px;
      cursor: pointer;
    }
  }
`;

export const FullModalContainer = styled.div`
  width: 100%;
  position: relative;
  background: white;
  max-width: 100%;

  .full-modal-header--wrapper {
    width: 100%;
    max-width: 1253px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    z-index: 9999;
    padding-left: 20px;
    padding-right: 20px;
  }

  .full-modal-header--logo {
    a {
      & > svg {
        width: 234px;
        @media (max-width: 991px) {
          width: 159px;
        }
      }
    }
  }

  .dynamic-form--container {
    box-shadow: none !important;
  }

  @media (max-width: 575px) {
    .full-modal-header--wrapper {
      display: none;
    }
  }
`;
