import React, { useEffect, useState } from 'react';
import { globalHistory } from '@reach/router';
import SweetScroll from 'sweet-scroll';

const SweetScrollContext = React.createContext({});

export default SweetScrollContext;

export const SweetScrollContextOverlay = ({ children }) => {
  const [globalScroll, setGlobalScroll] = useState(null);
  const [scrolledOnLoad, setScrolledOnLoad] = useState(false);
  const [mounted, setMounted] = useState(true);

  const calcHeaderHeightOption = () => {
    const headerDom1 = document.getElementsByClassName('header-container');
    const headerDom2 = document.getElementsByClassName('website-header');
    const headerAnchor = document.getElementById('anchor-menu-container');
    const headerHeight1 = headerDom1?.[0]?.clientHeight || false;
    const headerHeight2 = headerDom2?.[0]?.clientHeight || 0;
    const headerAnchorHeight = headerAnchor?.offsetHeight || 0;

    const scrollOptions = {
      offset: -((headerAnchorHeight || headerHeight2 || headerHeight1) + 70),
    };
    return scrollOptions;
  };

  const sweetScroll = (options = {}) => {
    if (typeof window !== 'undefined' && !globalScroll && mounted) {
      const scroll = new SweetScroll({
        trigger: 'a[href^="#"]',
        updateURL: true,
        ...calcHeaderHeightOption(),
        ...options,
        after: () => {
          window.dispatchEvent(new HashChangeEvent('hashchange'));
        },
      });
      setGlobalScroll(scroll);
      return scroll;
    }
    return globalScroll;
  };

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);

      if (globalScroll) {
        globalScroll.destroy();
      }
    };
  }, []);

  const updateScrollOption = () => {
    const scroll = sweetScroll();
    scroll?.update({ ...calcHeaderHeightOption() });
  };

  const scrollToAnchor = (id, option = {}) => {
    const scroll = new SweetScroll();
    scroll?.to(id, { ...calcHeaderHeightOption(), ...option });
  };

  const scrollToAnchorOnLoad = () => {
    if (!scrolledOnLoad) {
      const { hash } = globalHistory.location;

      if (hash && !hash.startsWith('#code=')) {
        scrollToAnchor(hash);
        setScrolledOnLoad(true);
      }
    }
  };

  return (
    <SweetScrollContext.Provider
      value={{
        sweetScroll,
        updateScrollOption,
        scrollToAnchor,
        scrollToAnchorOnLoad,
      }}
    >
      {children}
    </SweetScrollContext.Provider>
  );
};
