import React, { useEffect, useRef } from 'react';
import { Input } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RadioGroup from 'components/Core/RadioGroup';
import closeIcon from 'images/close-icon.png';
import { FormTitle } from '../styles';
import { OtherIndustryContainer } from './styles';

const OtherIndustry = ({
  dataCy,
  visible,
  dropdownList: dropdownOtherIndustry,
  isModalForm,
  onSelect,
  onBack,
  label = 'Please select your industry from the list below',
  className,
}) => {
  const containerRef = useRef();

  useEffect(() => {
    if (visible) containerRef?.current?.scrollIntoView?.();
    const parent = containerRef?.current?.parentElement;
    if (parent) {
      parent.style.overflow = visible ? 'hidden' : '';
    }
  }, [visible]);

  const formik = useFormik({
    initialValues: {
      otherSearch: '',
    },
    validationSchema: Yup.object().shape({
      otherIndustry: Yup.string().required('Industry is invalid'),
    }),
  });

  const onHandleBlur = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
    if (id !== 'otherSearch') {
      onSelect(id, newValue);
      formik.setFieldValue('otherSearch', '');
    }
  };

  return (
    <OtherIndustryContainer
      ref={containerRef}
      className={`other-industries-container ${className || ''}`}
      visible={visible}
      isModalForm={isModalForm}
      {...(dataCy && { 'data-cy': 'otherIndustriesContainer' })}
    >
      <div className="signup-form--other-industry-header">
        {onBack && (
          <button
            data-position="Form - CTA"
            type="button"
            className="slick-prev"
            onClick={onBack}
            {...(dataCy && { 'data-cy': 'otherIndustryBackButton' })}
          >
            Previous
          </button>
        )}
        <FormTitle className="signup-form--title" titleColor="black">
          {label}
        </FormTitle>
      </div>
      <div className="signup-form--other-industry-search">
        <Input
          data-cy="search"
          id="otherSearch"
          type="text"
          placeholder="Type to search..."
          disableUnderline
          fullWidth
          value={formik.values.otherSearch}
          onChange={(e) => onHandleBlur('otherSearch', e.target.value)}
        />
        {formik.values.otherSearch && formik.values.otherSearch.length > 0 && (
          <img
            src={closeIcon}
            className="signup-form--other-industry-close"
            alt="close-icon"
            onClick={() => onHandleBlur('otherSearch', '')}
          />
        )}
      </div>
      <div className="signup-form--other-industry-radio">
        <RadioGroup
          dataCy={dataCy}
          id="otherIndustry"
          options={dropdownOtherIndustry?.filter(
            (item) =>
              !!item.value &&
              (!formik.values.otherSearch ||
                item.label.toLowerCase().includes(formik.values.otherSearch.toLowerCase()) ||
                item.label
                  .toLowerCase()
                  .split(' ')
                  .some((word) =>
                    word.toLowerCase().includes(formik.values.otherSearch.toLowerCase()),
                  )),
          )}
          formValid
          handleFocus={onHandleBlur}
        />
      </div>
    </OtherIndustryContainer>
  );
};

export default OtherIndustry;
