import React, { useState, useEffect } from 'react';
import Modal from 'components/Core/Modal';
import Button from 'components/Core/Button';
import useNormalDQFlow from 'hooks/useNormalDQFlow';
import { initEmbedMarketoForm } from 'services/marketoService';
import snackbar from 'utils/snackbar';
import { MarketoEmbeddedContent } from './styles';

const MarketoEmbedded = ({
  className,
  dataPosition,
  formType,
  marketoFormId,
  modalMode,
  closeModal,
  title,
  onSuccess,
  shouldRedirect,
  theme,
  thankyou,
  thankyouSnackbar,
  submittedCtaLabel,
  ignoreSubmitted,
}) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const DQFlow = useNormalDQFlow();

  const handleSuccess = () => {
    if (onSuccess) {
      if (thankyouSnackbar) {
        snackbar(thankyou || 'Thanks for submitting.');
      }

      onSuccess();
    }

    if (modalMode) {
      closeModal();
    }
  };

  useEffect(() => {
    initEmbedMarketoForm(
      marketoFormId,
      setFormSubmitted,
      handleSuccess,
      shouldRedirect,
      dataPosition,
      formType,
      DQFlow,
    );
  }, []);

  const formContent = (
    <MarketoEmbeddedContent
      className={`marketo-embedded-form ${className}`}
      modalMode={modalMode}
      theme={theme}
    >
      <div
        className={`marketo-embedded--container ${
          !ignoreSubmitted && isFormSubmitted ? ' thanks-container' : ''
        }`}
      >
        {ignoreSubmitted || !isFormSubmitted ? (
          <>
            {title && <h2 className="marketo-embedded--form-title">{title}</h2>}
            <form data-formId={marketoFormId} className="mktoForm" />
          </>
        ) : (
          <>
            <h2>You've already submitted form.</h2>
            <p>{thankyou || 'Thanks for submitting.'}</p>
            {onSuccess && <Button onClick={handleSuccess}>{submittedCtaLabel || 'Ok'}</Button>}
          </>
        )}
      </div>
    </MarketoEmbeddedContent>
  );

  return modalMode ? (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      handleClose={() => setModalOpen(false)}
    >
      {formContent}
    </Modal>
  ) : (
    formContent
  );
};

export default MarketoEmbedded;
