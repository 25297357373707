import React from 'react';
import MUIFormControl from '@mui/material/FormControl';

const FormControl = ({ children, ...otherProps }) => {
  return <MUIFormControl {...otherProps}>{children}</MUIFormControl>;
};

export default FormControl;

export const WithFormControl = (FormField) => (props) => {
  const {
    fullWidth = true,
    className,
    sx,
    ...otherFormControlProps
  } = props.formControlProps || {};
  return (
    <MUIFormControl
      fullWidth={fullWidth}
      className={`form-item ${className || ''}`}
      sx={{
        marginBottom: '15px',
        position: 'inherit',
        ...sx,
      }}
      {...otherFormControlProps}
    >
      <FormField {...props} />
    </MUIFormControl>
  );
};
