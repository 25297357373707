import React from 'react';

const NavLogoContext = React.createContext({});

export default NavLogoContext;

export const WithNavLogoContext = (WrappedComponent) => (props) => {
  return (
    <NavLogoContext.Consumer>
      {(contextProps) => (
        <WrappedComponent
          currLogoType={contextProps?.currLogoType}
          prevLogoType={contextProps?.prevLogoType}
          {...props}
        />
      )}
    </NavLogoContext.Consumer>
  );
};
