import useBreakpoint from 'hooks/useBreakpoint';

const useBreakpointView = (breakpoints = ['xs', 'sm']) => {
  const { breakpoint } = useBreakpoint();

  if (breakpoint === null) {
    return null;
  }

  return breakpoints?.includes(breakpoint) || false;
};

export default useBreakpointView;
