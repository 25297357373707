/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const NumberInputFieldContainer = styled.div`
  .number-input-field--label {
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-700);
    letter-spacing: var(--letter-spacing--0-01);
    line-height: var(--line-height-120);
    color: var(--darkest);
    margin-bottom: 15px;
  }

  .number-input-field--wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .number-input-field--minus,
    .number-input-field--plus {
      width: 80px;
      height: 60px;
      font-size: var(--font-size-25);
      line-height: var(--line-height-150);
      border: 1px solid var(--grey-4);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--darkest);
      cursor: pointer;

      &:hover {
        border: 1px solid var(--titan-blue-3);
      }
    }

    .number-input-field--input {
      flex: 1;
      position: relative;
      margin: 0 10px;

      .text-field-input {
        .MuiFormLabel-root {
          font-size: var(--font-size-20) !important;
        }
        .MuiInputBase-root {
          border-radius: 0px;
          font-size: var(--font-size-20) !important;
          input {
            text-align: center;
            height: 60px;
            padding: 0px 14px;
          }

          &:hover {
            .MuiOutlinedInput-notchedOutline {
              border-color: var(--titan-blue-3);
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .number-input-field--wrapper {
      .number-input-field--input {
        position: initial;
      }
    }
  }

  @media (max-width: 767px) {
    .number-input-field--label {
      font-size: var(--font-size-18);
    }

    .number-input-field--wrapper {
      .number-input-field--minus,
      .number-input-field--plus {
        width: 60px;
        height: 56px;
        font-size: var(--font-size-20);
      }

      .number-input-field--input {
        .text-field-input {
          .MuiInputBase-root {
            input {
              height: 56px;
            }
          }
        }
      }
    }
  }
`;
