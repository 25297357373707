import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from 'components/Core/Tooltip';
import { WithFormControl } from 'components/Core/FormControl';

const FormLabelWithField = ({
  label,
  isRequired,
  helperText,
  className,
  children,
  ...otherProps
}) => {
  return (
    <FormFieldContainer
      className={'form-field--container' + (className != undefined ? ' ' + className : '')}
    >
      <div className="form-field--label-container">
        <label
          className={'form-field--label' + (isRequired ? ' required' : '') + ' font-heading-md'}
        >
          {label}
          {helperText != '' && (
            <Tooltip
              placement="top-end"
              title={helperText}
              tooltipStyle={{ maxWidth: 500, fontSize: 'var(--font-size-p)', padding: 25 }}
            >
              <span className="form-field--helper">?</span>
            </Tooltip>
          )}
        </label>
      </div>
      {children && <div className="form-field--input-container">{children}</div>}
    </FormFieldContainer>
  );
};

FormLabelWithField.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
};

FormLabelWithField.defaultProps = {
  label: '',
  isRequired: false,
  helperText: '',
};

const FormFieldContainer = styled.div`
  .form-field--label-container {
    display: flex;

    .form-field--label {
      &:before {
        content: ' ';
      }
    }

    .form-field--label.required {
      &:before {
        content: '*';
        padding-right: 5px;
      }
    }

    .form-field--helper {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      display: inline-block;
      border-radius: 50%;
      background: var(--titan-blue-4);
      font-weight: var(--font-weight-700);
      font-size: var(--font-size-13);
      line-height: var(--line-height-175);
      text-align: center;
      color: var(--white);
      margin-left: 10px;
      cursor: pointer;
      vertical-align: top;
    }
  }

  .form-field--input-container {
    margin-top: 15px;

    & > div {
      width: 100%;
      margin-top: 0;
      height: 100%;
    }
  }

  @media (max-width: 767px) {
    .form-field--label-container {
      .form-field--label {
        font-size: var(--font-size-18);
      }
    }

    .form-field--input-container {
      input {
        height: 48px;
      }
    }
  }

  @media (max-width: 575px) {
  }
`;

export default WithFormControl(FormLabelWithField);
