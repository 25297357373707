import { graphql, useStaticQuery } from 'gatsby';

const useMarketoFormData = (id) => {
  const data = useStaticQuery(graphql`
    query {
      allMarketoForm {
        edges {
          node {
            id: formId
            buttonLabel
            fields: marketoFields {
              id
              dataType
              defaultValue
              fieldMetaData {
                multiSelect
                values {
                  label
                  value
                }
              }
              label
              required
              instructions
              validationMessage
              fields
              visibilityRules {
                ruleType
                rules {
                  subjectField
                  operator
                  values
                }
              }
            }
          }
        }
      }
    }
  `);

  const forms = data?.allMarketoForm?.edges?.map((edge) => edge.node) || [];

  if (id) {
    return forms.find((x) => String(x.id) === String(id));
  }

  return forms;
};

export default useMarketoFormData;
