export const stateList = [
  { label: 'Select a state...', value: '' },
  { label: 'Alabama', value: 'Alabama', code: 'AL' },
  { label: 'Alaska', value: 'Alaska', code: 'AK' },
  { label: 'Arizona', value: 'Arizona', code: 'AZ' },
  { label: 'Arkansas', value: 'Arkansas', code: 'AR' },
  { label: 'California', value: 'California', code: 'CA' },
  { label: 'Colorado', value: 'Colorado', code: 'CO' },
  { label: 'Connecticut', value: 'Connecticut', code: 'CT' },
  { label: 'Washington DC', value: 'Washington DC', code: 'DC' },
  { label: 'Delaware', value: 'Delaware', code: 'DE' },
  { label: 'Florida', value: 'Florida', code: 'FL' },
  { label: 'Georgia', value: 'Georgia', code: 'GA' },
  { label: 'Hawaii', value: 'Hawaii', code: 'HI' },
  { label: 'Idaho', value: 'Idaho', code: 'ID' },
  { label: 'Illinois', value: 'Illinois', code: 'IL' },
  { label: 'Indiana', value: 'Indiana', code: 'IN' },
  { label: 'Iowa', value: 'Iowa', code: 'IA' },
  { label: 'Kansas', value: 'Kansas', code: 'KS' },
  { label: 'Kentucky', value: 'Kentucky', code: 'KY' },
  { label: 'Louisiana', value: 'Louisiana', code: 'LA' },
  { label: 'Maine', value: 'Maine', code: 'ME' },
  { label: 'Maryland', value: 'Maryland', code: 'MD' },
  { label: 'Massachusetts', value: 'Massachusetts', code: 'MA' },
  { label: 'Michigan', value: 'Michigan', code: 'MI' },
  { label: 'Minnesota', value: 'Minnesota', code: 'MN' },
  { label: 'Mississippi', value: 'Mississippi', code: 'MS' },
  { label: 'Missouri', value: 'Missouri', code: 'MO' },
  { label: 'Montana', value: 'Montana', code: 'MT' },
  { label: 'Nebraska', value: 'Nebraska', code: 'NE' },
  { label: 'Nevada', value: 'Nevada', code: 'NV' },
  { label: 'New Hampshire', value: 'New Hampshire', code: 'NH' },
  { label: 'New Jersey', value: 'New Jersey', code: 'NJ' },
  { label: 'New Mexico', value: 'New Mexico', code: 'NM' },
  { label: 'New York', value: 'New York', code: 'NY' },
  { label: 'North Carolina', value: 'North Carolina', code: 'NC' },
  { label: 'North Dakota', value: 'North Dakota', code: 'ND' },
  { label: 'Ohio', value: 'Ohio', code: 'OH' },
  { label: 'Oklahoma', value: 'Oklahoma', code: 'OK' },
  { label: 'Oregon', value: 'Oregon', code: 'OR' },
  { label: 'Pennsylvania', value: 'Pennsylvania', code: 'PA' },
  { label: 'Rhode Island', value: 'Rhode Island', code: 'RI' },
  { label: 'South Carolina', value: 'South Carolina', code: 'SC' },
  { label: 'South Dakota', value: 'South Dakota', code: 'SD' },
  { label: 'Tennessee', value: 'Tennessee', code: 'TN' },
  { label: 'Texas', value: 'Texas', code: 'TX' },
  { label: 'Utah', value: 'Utah', code: 'UT' },
  { label: 'Vermont', value: 'Vermont', code: 'VT' },
  { label: 'Virginia', value: 'Virginia', code: 'VA' },
  { label: 'Washington', value: 'Washington', code: 'WA' },
  { label: 'West Virginia', value: 'West Virginia', code: 'WV' },
  { label: 'Wisconsin', value: 'Wisconsin', code: 'WI' },
  { label: 'Wyoming', value: 'Wyoming', code: 'WY' },
];

export const technicianOptions = [
  { label: '1 tech', value: '1' },
  { label: '2 techs', value: '2' },
  { label: '3-4 techs', value: '3' },
  { label: '5-9 techs', value: '5' },
  { label: '10-19 techs', value: '10' },
  { label: '20-29 techs', value: '20' },
  { label: '30-39 techs', value: '30' },
  { label: '40+ techs', value: '40' },
];

export const staffCountOptions = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2 or more', value: '2' },
];

export const focusOptions = [
  { label: 'Residential', value: 'Residential' },
  { label: 'Commercial', value: 'Commercial' },
];

export const typeOfWorkOptions = [
  { label: 'Service and Replacement', value: 'Service & Replacement' },
  { label: 'Construction or Remodel', value: 'Construction or Remodel' },
];
