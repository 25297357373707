import styled from 'styled-components';

export const CheckboxesTitle = styled.label`
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-145);
  color: var(--grey-8);
  margin-bottom: 16px;
  p {
    padding: 0px 9px;
    margin: 5px 0 5px 0;
    @media (max-width: 767px) {
      padding: 0px 5px;
    }
  }
`;

export const CheckboxesFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;

  .checkboxes-div {
    width: 100%;
    height: auto;
    margin: 9px;
    color: var(--grey-8);
    float: none;
    flex: 45%;
    font-size: var(--font-size-16);

    input[type='checkbox'] {
      display: none;
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    label {
      position: relative;
      padding: 24px 16px;
      line-height: var(--line-height-150);
      cursor: pointer;
      border: 1px solid var(--grey-4);
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      text-align: center;
      font-size: var(--font-size-20);
      font-weight: var(--font-weight-400);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--darkest);
      &:hover {
        background-color: rgba(0, 118, 173, 0.05);
      }
    }

    &:last-child label {
      border-left: 1px solid var(--grey-4);
    }
    &.selected:last-child label {
      border-left: 2px solid var(--titan-blue-3);
    }
    &.selected label {
      border-width: 3px;
      border-color: var(--titan-blue-3);
      color: var(--titan-blue-6);
    }
  }
  .checkboxes-field-error .checkboxes-div > label {
    border-left: 2px solid var(--critical-3);
  }

  @media (max-width: 767px) {
    height: 48px;
    .checkboxes-div {
      label {
        font-size: var(--font-size-13);
      }
    }
  }
`;
