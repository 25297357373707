import React from 'react';
import styled from 'styled-components';
import { getColor } from 'utils/colorUtility';
import textPropTypes from './utils/textPropTypes';

import { sharedTitleStlyes, paragraphStyles, overlineStyles } from './styles';

const Text = (props) => {
  const { children, color, size, type = '', content, ...otherProps } = props;
  const headingType = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(size);
  const textColor = color === 'Choose a value' ? 'var(--dark)' : color && getColor(color);
  const lowercaseSize = size?.toLowerCase();

  switch (type.toLowerCase()) {
    case 'h1':
      return (
        <H1Styled textColor={textColor} textSize={lowercaseSize || 'h1'} {...otherProps}>
          {children}
        </H1Styled>
      );
    case 'h2':
      return (
        <H2Styled textColor={textColor} textSize={lowercaseSize || 'h2'} {...otherProps}>
          {children}
        </H2Styled>
      );
    case 'h3':
      return (
        <H3Styled textColor={textColor} textSize={lowercaseSize || 'h3'} {...otherProps}>
          {children}
        </H3Styled>
      );
    case 'h4':
      return (
        <H4Styled textColor={textColor} textSize={lowercaseSize || 'h4'} {...otherProps}>
          {children}
        </H4Styled>
      );
    case 'h5':
      return (
        <H5Styled textColor={textColor} textSize={lowercaseSize || 'h5'} {...otherProps}>
          {children}
        </H5Styled>
      );
    case 'h6':
      return (
        <H6Styled textColor={textColor} textSize={lowercaseSize || 'h6'} {...otherProps}>
          {children}
        </H6Styled>
      );
    case 'p':
      return (
        <PStyled
          headingType={headingType}
          textColor={textColor}
          textSize={lowercaseSize || 'medium'}
          {...otherProps}
        >
          {children}
        </PStyled>
      );
    case 'overline':
      return (
        <OverlineStyled textColor={textColor} {...otherProps}>
          {children}
        </OverlineStyled>
      );
    default:
      return null;
  }
};

const H1Styled = styled.h1`
  ${({ textSize }) => (textSize === 'overline' ? overlineStyles : sharedTitleStlyes)};
`;

const H2Styled = styled.h2`
  ${({ textSize }) => (textSize === 'overline' ? overlineStyles : sharedTitleStlyes)};
`;

const H3Styled = styled.h3`
  ${({ textSize }) => (textSize === 'overline' ? overlineStyles : sharedTitleStlyes)};
`;

const H4Styled = styled.h4`
  ${({ textSize }) => (textSize === 'overline' ? overlineStyles : sharedTitleStlyes)};
`;

const H5Styled = styled.h5`
  ${({ textSize }) => (textSize === 'overline' ? overlineStyles : sharedTitleStlyes)};
`;

const H6Styled = styled.h6`
  ${({ textSize }) => (textSize === 'overline' ? overlineStyles : sharedTitleStlyes)};
`;

const PStyled = styled.p`
  ${({ headingType }) => (headingType ? sharedTitleStlyes : paragraphStyles)}
`;

const OverlineStyled = styled.p`
  ${overlineStyles}
`;

Text.propTypes = {
  ...textPropTypes,
};

export default Text;
