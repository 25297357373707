import React, { useState, useEffect } from 'react';
import SelectEnhanced, { toggleClass } from 'components/Core/Select/SelectEnhanced';
import OtherIndustry from 'components/Form/PricingForm/OtherIndustry';
import { FormContainer } from 'components/Form/DynamicMarketoForm/styles';
import { OtherOptionsContainer, OtherOptionsModal } from './styled';
import PrefilledCheck from './PrefilledCheck';

const OtherOptions = React.forwardRef((props, ref) => {
  const { visible, dataCy, label, options, handleChange, prevClick } = props;
  return (
    <OtherOptionsContainer
      className="other-options-container"
      ref={ref}
      visible={visible}
      {...(dataCy && { 'data-cy': 'otherIndustriesStep' })}
    >
      <OtherIndustry
        label={label}
        dropdownList={options}
        onSelect={handleChange}
        onBack={prevClick}
        visible
        dataCy={dataCy}
      />
    </OtherOptionsContainer>
  );
});

const IndustrySelector = ({
  otherOptions = [],
  otherLabel = 'Select your industry',
  otherId = 'industryTestVerticals',
  handleChange,
  dataCy,
  isModalForm: inlineOptions,
  $prefillValue,
  value,
  values,
  options = [],
  ...otherProps
}) => {
  const [currentValue, setCurrentValue] = useState(value !== 'Other' ? value : values?.[otherId]);
  const [resetValue, setResetValue] = useState('');
  const [currentOptions, setCurrentOptions] = useState(
    value !== 'Other' && !otherOptions.find((x) => x.value === value) ? options : otherOptions,
  );
  const [otherOptionsVisible, setOtherOptionsVisible] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  const [refreshingFocus, setRefreshingFocus] = useState(false);
  const container = React.createRef();
  const prefill = $prefillValue && $prefillValue === currentValue ? 'true' : null;
  const allIndustries = [...options.map((x) => x.value), ...otherOptions.map((x) => x.value)];

  useEffect(() => {
    if (value && !allIndustries.includes(value)) {
      handleChange?.(otherProps.id, '', true);
      setCurrentValue('');
      return;
    }

    if (value === 'Other' && !values?.[otherId] && !valueChanged) {
      handleChange?.(otherProps.id, '', true);
      setCurrentValue('');
      return;
    }

    if (value !== 'Other' && values?.[otherId]) {
      delete values?.[otherId];
    }

    setCurrentValue(value !== 'Other' ? value : values?.[otherId]);
    setCurrentOptions(
      value !== 'Other' && !otherOptions.find((x) => x.value === value) ? options : otherOptions,
    );
  }, [value, values]);

  const optionSelected = (id, value) => {
    setValueChanged(true);
    handleChange?.(id, value);

    delete values?.[otherId];

    if (value === 'Other') {
      setResetValue(currentValue);
      setShowOptions(false);
      setOtherOptionsVisible(true);
      setCurrentOptions(otherOptions);
      toggleClass(container, true);
      setCurrentValue(value);
    } else {
      setCurrentValue(value);
      setShowOptions(false);
      setCurrentOptions(options);
    }
  };

  const prevClick = () => {
    setShowOptions(false);
    setOtherOptionsVisible(false);
    toggleClass(container, false);
  };

  const reset = () => {
    if (values?.[otherProps?.id] === 'Other' && !values?.[otherId]) {
      values[otherProps.id] = resetValue;
      if (resetValue === $prefillValue) {
        setValueChanged(false);
      }
      setCurrentValue(resetValue);
      handleChange(otherProps.id, resetValue);
    }
  };

  const prevOtherClick = () => {
    setOtherOptionsVisible(false);
    setShowOptions(true);
    reset();
  };

  const prevOtherClose = () => {
    prevClick();
    reset();
    setRefreshingFocus(!refreshingFocus);
  };

  const handleOtherChange = (id, newValue) => {
    setCurrentValue(newValue);
    setCurrentOptions(otherOptions);
    handleChange(otherId, newValue);
    setOtherOptionsVisible(false);
    setShowOptions(false);
    toggleClass(container, false);
  };

  return (
    <>
      <SelectEnhanced
        dataCy={dataCy}
        value={currentValue}
        options={options}
        currentOptions={currentOptions}
        handleChange={optionSelected}
        showOptions={showOptions}
        onPrevClick={prevClick}
        isModalForm={inlineOptions}
        className={prefill && !valueChanged ? 'prefilled' : ''}
        HeaderComponent={prefill && !valueChanged && <PrefilledCheck />}
        refreshingFocus={refreshingFocus}
        {...otherProps}
      />
      {inlineOptions ? (
        <OtherOptions
          ref={container}
          visible={otherOptionsVisible}
          dataCy={dataCy}
          label={otherLabel}
          options={otherOptions}
          handleChange={handleOtherChange}
          prevClick={prevOtherClick}
        />
      ) : (
        <OtherOptionsModal
          className="modal-marketing-form--modal other-options-modal"
          open={otherOptionsVisible}
          handleClose={prevOtherClose}
          width={600}
          transitionDuration={0}
        >
          <FormContainer className="dynamic-form--container">
            <OtherOptions
              ref={container}
              visible={otherOptionsVisible}
              dataCy={dataCy}
              label={otherLabel}
              options={otherOptions}
              handleChange={handleOtherChange}
              prevClick={prevOtherClick}
            />
          </FormContainer>
        </OtherOptionsModal>
      )}
    </>
  );
};

export default IndustrySelector;
