import { css } from 'styled-components';

const sharedTitleStlyes = css`
  color: ${({ textColor }) => textColor};

  a {
    color: ${({ textColor }) => textColor};
  }
  font-size: ${({ textSize }) => `var(--font-size-${textSize})`};
  line-height: ${({ lineHeightMobile, lineHeightDesktop }) =>
    lineHeightDesktop || lineHeightMobile || '120%'};
  letter-spacing: ${({ textSize, letterSpacing }) =>
    letterSpacing || `var(--letter-spacing-${textSize})`};
  margin: ${({
    headingSpacingDesktop,
    headingSpacingMobile,
    marginDesktop,
    marginMobile,
    removeTextSpacing,
  }) =>
    removeTextSpacing
      ? 0
      : headingSpacingDesktop || headingSpacingMobile || marginDesktop || marginMobile};
  text-align: ${({ alignMobile, alignDesktop }) => {
    return alignDesktop || alignMobile;
  }};
  width: 100%;

  @media (max-width: 767px) {
    font-size: ${({ textSize }) => `var(--font-size-mobile-${textSize})`};
    letter-spacing: ${({ textSize, letterSpacing }) =>
      letterSpacing || `var(--letter-spacing-mobile-${textSize})`};
    line-height: ${({ lineHeightMobile }) => lineHeightMobile};
    margin: ${({ headingSpacingMobile, marginMobile, removeTextSpacing }) =>
      removeTextSpacing ? 0 : headingSpacingMobile || marginMobile};
    text-align: ${({ alignMobile }) => alignMobile};
  }
`;

const paragraphStyles = css`
  color: ${({ textColor }) => textColor};
  font-size: ${({ textSize }) =>
    textSize === 'large'
      ? 'var(--font-size-p-lg)'
      : textSize === 'medium'
      ? 'var(--font-size-p-md)'
      : textSize === 'normal'
      ? 'var(--font-size-p)'
      : textSize === 'small'
      ? 'var(--font-size-p-sm)'
      : textSize};
  letter-spacing: ${({ textSize }) => (textSize === 'large' ? '-0.01%' : '0')};
  line-height: ${({ lineHeightDesktop, lineHeightMobile }) =>
    lineHeightDesktop || lineHeightMobile || '150%'};
  margin: ${({
    paragraphSpacingDesktop,
    paragraphSpacingMobile,
    marginDesktop,
    marginMobile,
    removeTextSpacing,
  }) =>
    removeTextSpacing
      ? 0
      : paragraphSpacingDesktop || paragraphSpacingMobile || marginDesktop || marginMobile};
  text-align: ${({ alignMobile, alignDesktop }) => alignDesktop || alignMobile};
  white-space: pre-wrap;
  width: 100%;

  @media (max-width: 767px) {
    font-size: ${({ textSize }) =>
      textSize === 'large'
        ? 'var(--font-size-mobile-p-lg)'
        : textSize === 'medium'
        ? 'var(--font-size-mobile-p-md)'
        : textSize === 'normal'
        ? 'var(--font-size-mobile-p)'
        : textSize === 'small'
        ? 'var(--font-size-mobile-p-sm)'
        : textSize};
    line-height: ${({ lineHeightMobile }) => lineHeightMobile || '150%'};
    margin: ${({ marginMobile, paragraphSpacingMobile, removeTextSpacing }) =>
      removeTextSpacing ? 0 : paragraphSpacingMobile || marginMobile};
    text-align: ${({ alignMobile }) => alignMobile};
  }
`;

const overlineStyles = css`
  color: ${({ textColor }) => textColor || 'var(--grey-8)'};
  font-family: var(--font-family-heading);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-900);
  letter-spacing: 0.05em;
  line-height: var(--line-height-110);
  margin: ${({ headingSpacingDesktop, headingSpacingMobile }) =>
    headingSpacingDesktop || headingSpacingMobile || '0 0 15px 0'};
  text-align: ${({ alignMobile, alignDesktop }) => alignDesktop || alignMobile};
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: var(--font-size-14);
    text-align: ${({ alignMobile }) => alignMobile};
    margin: ${({ headingSpacingMobile }) => headingSpacingMobile || '0 0 15px 0'};
  }
`;

export { sharedTitleStlyes, overlineStyles, paragraphStyles };
