export const initSteps = (marketoFormDetails) => {
  const { fields } = marketoFormDetails || {};

  const stepData =
    (marketoFormDetails &&
      fields
        ?.filter((x) => x.dataType === 'fieldset')
        .map((x) => ({
          ...x,
          fields: fields?.filter((y) => x.fields?.includes(y.id)),
        }))) ||
    [];

  if (marketoFormDetails && stepData.length === 0) {
    stepData.push({
      fields,
    });
  }
  return stepData;
};

export const evalVisibilityRule = (x, ruleType, values) => {
  let meetsCondition;
  switch (x.operator) {
    case 'is':
      meetsCondition = x.values.includes(values[x.subjectField]);
      break;
    case 'isNot':
      meetsCondition = !x.values.includes(values[x.subjectField]);
      break;
    case 'isEmpty':
      meetsCondition = !values[x.subjectField] || values[x.subjectField].length === 0;
      break;
    case 'isNotEmpty':
      meetsCondition = values[x.subjectField] && values[x.subjectField].length > 0;
      break;
    case 'contains':
      meetsCondition = x.values.some(
        (y) => values[x.subjectField] && values[x.subjectField].includes(y),
      );
      break;
    case 'notContains':
      meetsCondition = x.values.every(
        (y) => !values[x.subjectField] || !values[x.subjectField].includes(y),
      );
      break;
    case 'startsWith':
      meetsCondition = values[x.subjectField].startsWith(x.values);
      break;
    case 'notStartsWith':
      meetsCondition = !values[x.subjectField].startsWith(x.values);
      break;
    case 'between':
      meetsCondition = x.values[0] < values[x.subjectField] && x.values[1] > values[x.subjectField];
      break;
    case 'notBetween':
      meetsCondition = !(
        x.values[0] < values[x.subjectField] && x.values[1] > values[x.subjectField]
      );
      break;
    case 'greaterThan':
      meetsCondition = x.values.every((y) => y < values[x.subjectField]);
      break;
    case 'lessThan':
      meetsCondition = x.values.every((y) => y > values[x.subjectField]);
      break;
    case 'atLeast':
      meetsCondition = x.values.every((y) => y <= values[x.subjectField]);
      break;
    case 'atMost':
      meetsCondition = x.values.every((y) => y >= values[x.subjectField]);
      break;
    default:
      meetsCondition = false;
      break;
  }

  return ruleType === 'show' ? meetsCondition : ruleType === 'hide' ? !meetsCondition : false;
};
