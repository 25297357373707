import styled from 'styled-components';

const VideoContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  box-sizing: content-box;
  overflow: hidden;

  .video-play-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    z-index: 503;
    transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background: transparent;
    border: none;

    &:before {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: var(--titan-blue-3);
      opacity: 0;
    }

    &:after {
      width: 70px;
      height: 70px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: var(--titan-blue-3);
      box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
        0 -6px 16px -6px rgba(0, 0, 0, 0.025);
      animation: shadow-pulse 5s infinite;
    }

    &:after,
    &:before {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: 0.2s;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      &:before {
        opacity: 0.2;
        transform: scale(1.2);
      }

      &:after {
        animation: none;
      }
    }

    &.video-play-button--visible {
      pointer-events: all;
      opacity: 1;
    }

    .video-play-button-icon {
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      background-color: transparent;
      z-index: 2;

      svg {
        margin-left: 3px;

        width: 100%;
        height: 100%;
        fill: var(--white);
      }
    }
  }

  .video-play-button-label {
    background: transparent;
    border: none;
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-700);
    line-height: 25px;
    text-align: center;
    letter-spacing: var(--letter-spacing--0-01);
    white-space: nowrap;
    padding: 0;
    margin: 0;
    cursor: pointer;

    .video-play-button-icon {
      display: flex;
      align-items: center;

      svg {
        margin-top: -4px;
        margin-right: 5px;
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .video-preview-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;

    &.video-preview-container--hide {
      display: none;
    }

    .video-preview-video {
      width: 100%;
      height: auto;
    }
  }

  .video-mainview-container {
    display: none;

    &.video-mainview-container--visible {
      display: block;
    }

    .video-main-video-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(50, 50, 93, 0.74);
      opacity: 0;
      transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      z-index: 501;
      display: flex;
      align-items: center;
      justify-content: center;

      &.video-main-video-container--visible {
        opacity: 1;
        z-index: 1001;
      }

      .video-main-video {
        width: 90%;
        height: auto;
        pointer-events: all;

        &:focus {
          outline: none;
        }
      }
    }
  }

  &.video-type-button {
    overflow: visible;

    .video-play-button {
      position: relative;
      margin: auto;
    }
  }

  &.video-box-shadow {
    box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.1);
  }

  &.video-rounded-corners {
    .video-preview-container {
      border-radius: 20px;
      border: 1px solid var(--grey-2);
    }
  }

  @media (max-width: 575px) {
    .video-preview-container {
      display: flex;
    }
  }
`;

export { VideoContainer };
