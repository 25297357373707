import { graphql, useStaticQuery } from 'gatsby';

const useNormalDQFlow = () => {
  const { contentfulIcp } = useStaticQuery(graphql`
    query {
      contentfulIcp(internalName: { eq: "Normal DQ Flow" }) {
        ...Icp
      }
    }
  `);

  return contentfulIcp;
};

export default useNormalDQFlow;
