import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import isNil from 'utils/isNil';

export const truncateText = (text, limit) => {
  if (!limit) return text;
  return text && (text.length <= limit ? text : `${text.slice(0, limit)}...`);
};

export const reduceContentValues = (content) =>
  content && Array.isArray(content)
    ? content.reduce(
        (prev, cur) => prev + (cur?.value || cur?.content?.reduce((p, c) => p + c?.value, '')),
        '',
      )
    : content?.content || content?.raw
    ? reduceContentValues(content?.content || JSON.parse(content?.raw))
    : '';

export const getSubheading = (excerpt, mainContent, truncateLength) => {
  const mainContentCopy = [];

  const mainContentoptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node) => {
        mainContentCopy.push(reduceContentValues(node?.content));
      },
      [BLOCKS.HEADING_2]: (node) => {
        mainContentCopy.push(reduceContentValues(node?.content));
      },
      [BLOCKS.HEADING_3]: (node) => {
        mainContentCopy.push(reduceContentValues(node?.content));
      },
      [BLOCKS.HEADING_4]: (node) => {
        mainContentCopy.push(reduceContentValues(node?.content));
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        children.forEach((child) => {
          if (child?.type === 'b') {
            mainContentCopy.push(child.props.children);
          } else if (child?.type === 'a') {
            mainContentCopy.push(` ${child?.props?.children[0]}`);
          } else {
            mainContentCopy.push(child);
          }
        });
      },
    },
  };

  if (!isNil(excerpt)) {
    return truncateText(excerpt, truncateLength);
  }
  if (!isNil(mainContent) && mainContent.raw) {
    renderRichText(mainContent, mainContentoptions);
    return truncateText(mainContentCopy.join(''), truncateLength);
  }

  return '';
};

export const taxonomyString = (taxonomy) => {
  if (taxonomy) {
    return [...(taxonomy.industries || []), ...(taxonomy.topics || [])].join(', ');
  }
  return null;
};

export const getRichTextWordCount = (richText) => {
  const { content } = richText?.raw ? JSON.parse(richText?.raw) : {};
  let totalWords = 0;
  content?.forEach((node) => {
    const { nodeType } = node;
    if (
      nodeType.includes('heading') ||
      ['unordered-list', 'ordered-list', 'paragraph'].includes(nodeType)
    ) {
      const nodeContent = node.content;
      nodeContent.forEach((contentNode) => {
        if (contentNode.nodeType === 'text') totalWords += contentNode?.value?.split(' ')?.length;
        if (contentNode.nodeType === 'hyperlink') {
          contentNode?.content?.forEach((hyperlinkNode) => {
            totalWords += hyperlinkNode?.value?.split(' ')?.length;
          });
        }
      });
    }
  });
  return totalWords;
};

export const getReadingMinutes = (numberOfWords) => Math.ceil(numberOfWords / 200);

export const getNodeText = (node) => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
  return null;
};

export const truthyFilter = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    const _acc = acc;
    if (obj[key] !== undefined && obj[key]) _acc[key] = obj[key];
    return _acc;
  }, {});
