import Cookies from 'js-cookie';
import queryString from 'query-string';

const clearCookies = (values) => {
  Object.keys(values).forEach((key) => {
    if (Cookies.get(key)) {
      Cookies.remove(key);
    }
  });
};

const getSetCookies = ({
  location = typeof window !== 'undefined' ? window.location : undefined,
  ...values
}) => {
  const queryStringParams = queryString.parse(location?.search);
  const tempValues = { ...queryStringParams, ...values };

  Object.keys(tempValues).forEach((key) => {
    if (queryStringParams[key]) {
      tempValues[key] = queryStringParams[key];
      Cookies.set(key, queryStringParams[key]);
    } else {
      const p = Cookies.get(key);
      if (p && p !== undefined) {
        tempValues[key] = p;
      }
    }
  });
  return tempValues;
};

const notAllowed = ['undefined', 'null', undefined, null, ''];

const getCookieSanitized = (key) => {
  const val = Cookies.get(key);
  if (notAllowed.includes(val)) {
    return '';
  }
  return val;
};

const setCookieSanitized = (key, val) => {
  if (notAllowed.includes(val)) {
    Cookies.set(key, '');
  } else {
    Cookies.set(key, val);
  }
};

export { clearCookies, getSetCookies, getCookieSanitized, setCookieSanitized };
