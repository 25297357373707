import { removeSlash } from 'utils/urlHelper';

const checkBigTechsFirst = (qualifications) => {
  return {
    conditionType: 'businessSize',
    conditions: { techs: [0, 29] },
    qualified: [qualifications],
    disqualified: 'thankyoudemo-lg',
  };
};

const localServicesFlowFinalCondition = [
  {
    conditionType: 'businessSize',
    conditions: { techs: [1, 1], office: [0.5] },
    qualified: 'thankyoudemo1',
    disqualified: null,
  },
  {
    conditionType: 'businessSize',
    conditions: { techs: [3, 4], office: [0.5] },
    qualified: 'thankyoudemo',
    disqualified: null,
  },
  {
    conditionType: 'businessSize',
    conditions: { techs: [5], office: [0.5] },
    qualified: 'thankyoudemo-lg',
    disqualified: null,
  },
];

export const localServicesFlowQualifications = checkBigTechsFirst({
  conditionType: 'areaOfFocus',
  conditions: ['Commercial', 'Residential'],
  qualified: [
    {
      conditionType: 'areaOfFocus',
      conditions: ['Commercial'],
      qualified: [
        {
          conditionType: 'industries',
          conditions: [
            'Carpet Cleaning',
            'Chimney Sweep and Hearth',
            'Cleaning / Maid Services',
            'Duct Cleaning',
            'Electrical',
            'Elevator & Escalator',
            'Fire Related Services',
            'Garage Door',
            'HVAC',
            'Irrigation',
            'Landscaping',
            'Lawn Care Services',
            'Locksmith',
            'Pest Control',
            'Plumbing',
            'Pool/Spa',
            'Restoration',
            'Roofing',
            'Security Systems',
            'Septic',
            'Tree Services',
            'Water Damage Services',
            'Water Treatment',
            'Window Cleaning',
          ],
          qualified: [
            {
              conditionType: 'industries',
              conditions: [
                'Carpet Cleaning',
                'Cleaning / Maid Services',
                'Locksmith',
                'Roofing',
                'Water Damage Services',
                'Restoration',
                'Window Cleaning',
              ],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [5], office: [1] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Construction', 'Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: null,
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: ['Pest Control', 'Tree Services', 'Elevator & Escalator'],
              qualified: [
                {
                  conditionType: 'businessFocus',
                  conditions: ['Construction', 'Service'],
                  qualified: 'thankyoudemo-ea-com',
                  disqualified: null,
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: [
                'HVAC',
                'Plumbing',
                'Electrical',
                'Garage Door',
                'Chimney Sweep and Hearth',
                'Water Treatment',
              ],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [3], office: [1] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Construction', 'Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: null,
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: ['Duct Cleaning'],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [5], office: [1] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: 'thankyoudemo-nc-com',
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: ['Irrigation', 'Landscaping', 'Lawn Care Services'],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [3], office: [1] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Construction', 'Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: null,
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: ['Pool/Spa', 'Septic', 'Fire Related Services', 'Security Systems'],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [3], office: [1] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: 'thankyoudemo-nc-com',
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
          ],
          disqualified: 'thankyoudemo-industry',
        },
      ],
      disqualified: null,
    },
    {
      conditionType: 'areaOfFocus',
      conditions: ['Residential'],
      qualified: [
        {
          conditionType: 'industries',
          conditions: [
            'Carpet Cleaning',
            'Chimney Sweep and Hearth',
            'Cleaning / Maid Services',
            'Duct Cleaning',
            'Electrical',
            'Elevator & Escalator',
            'Fire Related Services',
            'Garage Door',
            'HVAC',
            'Irrigation',
            'Landscaping',
            'Lawn Care Services',
            'Locksmith',
            'Pest Control',
            'Plumbing',
            'Pool/Spa',
            'Restoration',
            'Roofing',
            'Security Systems',
            'Septic',
            'Tree Services',
            'Water Damage Services',
            'Water Treatment',
            'Window Cleaning',
          ],
          qualified: [
            {
              conditionType: 'industries',
              conditions: ['Irrigation', 'Landscaping', 'Water Treatment'],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [3], office: [1] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Construction', 'Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: null,
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: ['Pool/Spa', 'Septic', 'Fire Related Services', 'Security Systems'],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [3], office: [1] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: 'thankyoudemo-nc-res',
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: ['Duct Cleaning'],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [5], office: [0.5] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: 'thankyoudemo-nc-res',
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: [
                'Carpet Cleaning',
                'Cleaning / Maid Services',
                'Locksmith',
                'Roofing',
                'Water Damage Services',
                'Restoration',
                'Window Cleaning',
              ],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [5], office: [1] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Construction', 'Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: null,
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: [
                'HVAC',
                'Plumbing',
                'Electrical',
                'Garage Door',
                'Chimney Sweep and Hearth',
                'Lawn Care Services',
              ],
              qualified: [
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [3], office: [1] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Construction', 'Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: null,
                    },
                  ],
                  disqualified: null,
                },
                {
                  conditionType: 'businessSize',
                  conditions: { techs: [1], office: [0.5] },
                  qualified: [
                    {
                      conditionType: 'businessFocus',
                      conditions: ['Service'],
                      qualified: localServicesFlowFinalCondition,
                      disqualified: 'thankyoudemo-nc-res',
                    },
                  ],
                  disqualified: 'thankyoudemo-size',
                },
              ],
              disqualified: null,
            },
            {
              conditionType: 'industries',
              conditions: ['Pest Control', 'Tree Services', 'Elevator & Escalator'],
              qualified: [
                {
                  conditionType: 'businessFocus',
                  conditions: ['Construction', 'Service'],
                  qualified: 'thankyoudemo-ea',
                  disqualified: null,
                },
              ],
              disqualified: null,
            },
          ],
          disqualified: 'thankyoudemo-industry',
        },
      ],
      disqualified: null,
    },
  ],
  disqualified: null,
});

const evalCondition = (icp) => {
  const { businessSize, businessFocus, areaOfFocus } = icp;
  if (businessSize) {
    return { techs: businessSize.tech.split(','), office: businessSize.office.split(',') };
  }
  if (areaOfFocus) {
    return areaOfFocus === 'Both' ? ['Commercial', 'Residential'] : [areaOfFocus];
  }
  if (businessFocus) {
    return businessFocus === 'Both' ? ['Construction', 'Service'] : [businessFocus];
  }
};

const removeEmptyString = (url) => (url === '' || !url ? null : url);

const initCondtionObj = (conditions) => {
  return conditions?.map((q) => {
    return {
      conditionType: q.conditionType,
      conditions: evalCondition(q),
      qualified: removeEmptyString(q.qUrl),
      disqualified: removeEmptyString(q.dqUrl),
      qualifiedMql: q.qUrlMql || false,
      disqualifiedMql: q.dqUrlMql || false,
    };
  });
};

const prepareSelectedIcpConditionFlow = (selectedIcpConditions, normalFlowFinalConditions) => {
  return selectedIcpConditions?.map((path) => {
    const reversedConditions = initCondtionObj(path).slice().reverse();

    reversedConditions.forEach((c, index) => {
      if (index === 0) {
        //last condition (reversed) with no qualified value should assume normalFlowFinalConditions
        if (!c.qualified) c.qualified = normalFlowFinalConditions;
      } else {
        //previous conditions with no qualified conditions should nest.
        if (!c.qualified) c.qualified = [reversedConditions[index - 1]];
      }
    });

    return reversedConditions[reversedConditions.length - 1];
  });
};

export const formatDQFlow = (DQFlow, selectedIndustry) => {
  const { icp, allIndustriesDq } = DQFlow;

  //Determine which index the selectedIndustry belongs to in the array of icpConditions.
  const icpIndex = icp.findIndex((i) =>
    i.industries.some((x) =>
      x.value ? x.value === selectedIndustry : x.name === selectedIndustry,
    ),
  );
  const icpCondition = icp[icpIndex];

  //Fetch condition details of icpCondition or return null if industry is not qualified
  const selectedIcpConditions = icpCondition?.conditions
    ? JSON.parse(icpCondition.conditions.conditions)
    : null;

  const { allIndustryChecks, finalQualifiers } = icpCondition || {};

  //Parse initial and final icpConditions
  const parsedAllIndustryChecks = allIndustryChecks?.conditions
    ? JSON.parse(allIndustryChecks.conditions.conditions)
    : null;
  const parsedFinalQualifiers = finalQualifiers?.conditions
    ? JSON.parse(finalQualifiers.conditions.conditions)
    : null;

  //initialize allIndustryChecks and finalQaulifiers into condition objects.
  const initialCheck = parsedAllIndustryChecks && initCondtionObj(parsedAllIndustryChecks[0]);
  const normalFlowFinalConditions =
    parsedFinalQualifiers && initCondtionObj(parsedFinalQualifiers[0]);

  //Create DQ flow object for selected industry.
  const selectedIndustryIcp = prepareSelectedIcpConditionFlow(
    selectedIcpConditions,
    normalFlowFinalConditions,
  );

  //Get list of all qualified industries
  const allIndustriesList = icp
    .map((i) => i.industries)
    .flat()
    .map((x) => x.value || x.name);

  //Final resulting DQ Flow without allIndustryChecks
  const finalFlow = {
    conditionType: 'industries',
    conditions: allIndustriesList,
    qualified: selectedIndustryIcp,
    disqualified: !!allIndustriesDq ? removeSlash(allIndustriesDq) : 'thankyoudemo-industry',
    disqualifiedMql: false,
    qualifiedMql: false,
  };

  //If exists, make allIndustryChecks root of DQ Flow
  if (initialCheck) {
    initialCheck[0].qualified = [finalFlow];
    return initialCheck[0];
  }
  return finalFlow;
};