import styled from 'styled-components';

export const RadioGroupTitle = styled.label`
  font-size: var(--font-size-16) !important;
  font-weight: var(--font-weight-600);
  line-height: 23px;
  color: var(--grey-8);
  margin-bottom: 16px;
`;

export const RadioFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  height: 58px;

  .radio-div {
    width: 50%;
    font-size: var(--font-size-16);
    margin-bottom: 0;
    float: left;
    flex: 1;
    color: var(--grey-8);

    input[type='radio'] {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    label {
      position: relative;
      padding: 11px 10px;
      line-height: normal;
      cursor: pointer;
      border: 1px solid var(--grey-4);
      transition: 0.3s;
      text-align: center;
      font-family: var(--font-family);
      font-size: var(--font-size-16);
      font-weight: var(--font-weight-500);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: var(--titan-blue-hover);
      }
    }

    input:checked + label,
    &.selected label {
      border-color: var(--titan-blue-3);
      color: var(--titan-blue-6);
    }
  }

  &.radio-field-error > .radio-div > label {
    border: 1px solid var(--critical-3) !important;
  }

  @media (max-width: 767px) {
    height: auto;
    min-height: 48px;

    .radio-div {
      label {
        font-size: var(--font-size-13);
      }
    }
  }
`;

export const RadioWrapper = styled.span`
  .tooltip-description {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    display: inline-block;
    border-radius: 50%;
    background: var(--titan-blue-4);
    font-weight: var(--font-weight-700);
    font-size: var(--font-size-13);
    line-height: var(--line-height-175);
    text-align: center;
    color: var(--white);
    margin-left: 10px;
    cursor: pointer;
    vertical-align: top;
  }
`;
