import React from 'react';
import Tooltip from 'components/Core/Tooltip';
import { STIcon } from 'components/Brand/Icons/STIcon';

const PrefilledCheck = () => {
  return (
    <Tooltip
      title="Pre-Filled Data - We used a trade that you previously searched for."
      trigger={['click', 'hover']}
      className="prefilledTooltip"
      dataCy="prefilledCheckmarkContent"
      overlayClassName="prefilledOverlay"
      arrowPointAtCenter
      color="white"
    >
      <STIcon type="prefilledCheck" />
    </Tooltip>
  );
};

export default PrefilledCheck;
