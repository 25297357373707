import React from 'react';

import { StylesContainer } from './styles';

const Styles = (props) => {
  const customCss = props?.customStyling?.customStyling?.replace(/\\/, '');

  return (
    <StylesContainer className="styles-container" {...props} $customCss={customCss}>
      {props.children}
    </StylesContainer>
  );
};

export default Styles;
