import { navigate } from 'gatsby';

const handleNavigate = (route, target) => {
  if (target === '_blank') {
    window.open(route, '_blank');
  } else if (route.includes('https://') && window) {
    window.location.href = route;
  } else {
    navigate(route);
  }
};
export default handleNavigate;
