/* eslint-disable import/prefer-default-export */
import { navigate } from 'gatsby';
import { removeSlash } from 'utils/urlHelper';
import { localServicesFlowQualifications, formatDQFlow } from './utils/redirectData';

const processFlow = (flowData, values) => {
  const {
    techs,
    officeStaff,
    areaOfFocus,
    industry,
    otherIndustry,
    typeOfBusiness,
    customDQ = { qualified: null, disqualified: null },
  } = values;
  let isQualified = false;

  switch (flowData.conditionType) {
    case 'areaOfFocus':
      isQualified =
        areaOfFocus === 'Both' || flowData.conditions.some((focus) => areaOfFocus.includes(focus));
      break;
    case 'industries':
      isQualified =
        flowData.conditions.includes(industry) || flowData.conditions.includes(otherIndustry);
      break;
    case 'businessFocus':
      isQualified =
        typeOfBusiness === 'Both' ||
        flowData.conditions.some((focus) => typeOfBusiness.includes(focus));
      break;
    case 'businessSize':
      isQualified = true;
      if (flowData.conditions.techs?.length >= 1) {
        isQualified &&= parseFloat(techs) >= flowData.conditions.techs[0];
      }

      if (flowData.conditions.techs?.length >= 2) {
        isQualified &&= parseFloat(techs) <= flowData.conditions.techs[1];
      }

      if (typeof officeStaff === 'number' || officeStaff) {
        if (flowData.conditions.office?.length >= 1) {
          isQualified &&= parseFloat(officeStaff) >= flowData.conditions.office[0];
        }

        if (flowData.conditions.office?.length >= 2) {
          isQualified &&= parseFloat(officeStaff) <= flowData.conditions.office[1];
        }
      }
      break;
    default:
      break;
  }

  if (!isQualified) {
    return flowData.disqualified && customDQ.disqualified
      ? { redirectUrl: customDQ.disqualified, leadStatus: customDQ.disqualifiedMql }
      : { redirectUrl: flowData.disqualified, leadStatus: flowData.disqualifiedMql };
  }

  if (typeof flowData.qualified === 'string') {
    return {
      redirectUrl: customDQ.qualified || flowData.qualified,
      leadStatus: customDQ.qualifiedMql || flowData.qualifiedMql,
    };
  }

  const results = flowData.qualified?.map((data) => processFlow(data, values));

  return results?.flat().filter((item) => item.redirectUrl !== null);
};

export const getFormRedirectValues = (values) => {
  return {
    techs: values.No_of_Technicians__c,
    officeStaff: values?.fullTimeOffice,
    areaOfFocus:
      values.businessFocusResorCommFORMHELPER && values.businessFocusNewConRemodelSRFORMHELPER
        ? values.businessFocusResorCommFORMHELPER
        : 'Both',
    industry: values.Primary_Industry__c,
    otherIndustry: values.industryTestVerticals,
    typeOfBusiness:
      values.businessFocusResorCommFORMHELPER && values.businessFocusNewConRemodelSRFORMHELPER
        ? values.businessFocusNewConRemodelSRFORMHELPER
        : 'Both',
    customDQ: values?.customDQ,
  };
};

export const FormRedirect = (DQFlow, formValues, returnType = '') => {
  let result = '';
  if (DQFlow === 'localService' || formValues.slug?.includes('local-services')) {
    result = processFlow(localServicesFlowQualifications, formValues);
  } else {
    const selectedIndustry =
      formValues.industry === 'Other' ? formValues.otherIndustry : formValues.industry;

    result = processFlow(formatDQFlow(DQFlow, selectedIndustry), formValues);
  }

  const resultObj = Array.isArray(result) ? result[0] : result;

  const { redirectUrl, leadStatus } = resultObj || {};

  if (returnType === 'redirectUrl') return redirectUrl;
  if (returnType === 'leadStatus') return leadStatus;
  if (redirectUrl?.length > 0) {
    navigate(`/${removeSlash(redirectUrl)}`);
  }
};