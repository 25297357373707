import React from 'react';
import Tooltip from 'components/Core/Tooltip';
import { RadioWrapper, RadioFieldWrapper, RadioGroupTitle } from './styled';

const RadioGroup = (props) => {
  const {
    id,
    nameType,
    formValid,
    handleFocus: onFocus,
    handleChange: onChange,
    values = {},
    options = [],
    tooltipText,
    dataCy,
  } = props;
  let { value } = props;
  value = value || (values && values[id]);

  const handleFocus = (val) => {
    if (onFocus !== undefined) onFocus(id, val);
  };

  const handleChange = (event) => {
    if (onChange !== undefined) onChange(id, event.target.value);
  };

  return (
    <RadioWrapper className="radio-field--group">
      {nameType && (
        <>
          <RadioGroupTitle
            htmlFor={id}
            className="radio-field--title"
            data-optimizely={`radio-title-${id}`}
          >
            {nameType}
            {tooltipText && (
              <Tooltip
                className="tooltip-description"
                dataCy="tooltipContent"
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tooltipText,
                    }}
                  />
                }
              />
            )}
          </RadioGroupTitle>
        </>
      )}
      <RadioFieldWrapper
        formValid={formValid}
        id={id}
        className={`radio-field-wrapper${
          formValid && formValid[id] === -1 ? ' radio-field-error' : ''
        }`}
      >
        {options.map((item, index) => {
          const checked = value === item.value;
          const labelId = `${id}-${Math.floor(Math.random() * 100000000)}`;
          const dataCyLabel = `${id}${item?.label?.replace(/\s/g, '')}`;

          return (
            <div
              className={`radio-div${checked ? ' selected' : ''}`}
              key={index}
              {...(dataCy && { 'data-cy': `${dataCyLabel}Div` })}
            >
              <input
                name={id}
                id={labelId}
                type="radio"
                value={item.value}
                onClick={handleChange}
                checked={checked}
                data-optimizely={`radio-input-${id}`}
                onChange={() => {}}
                {...(dataCy && { 'data-cy': `${dataCyLabel}Input` })}
              />
              <label
                htmlFor={labelId}
                onClick={() => handleFocus(item.value)}
                data-optimizely={`radio-label-${id}`}
                {...(dataCy && { 'data-cy': `${dataCyLabel}Label` })}
              >
                {item.label}
              </label>
            </div>
          );
        })}
      </RadioFieldWrapper>
    </RadioWrapper>
  );
};

export default RadioGroup;
