import { enqueueSnackbar, closeSnackbar } from 'notistack';
import './style.less';

const snackbar = (message, type = 'success', option) => {
  const styles = {
    success: {
      background: 'var(--green)',
    },
  };

  const key = enqueueSnackbar(message, {
    variant: type,
    style: styles[type],
    anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
    className: 'snackbar-notification',
    preventDuplicate: true,
    hideIconVariant: true,
    maxSnack: option?.maxCount || 5,
    autoHideDuration: (option?.duration || 3) * 1000,
    disableWindowBlurListener: true,
    SnackbarProps: {
      onClick: () => {
        closeSnackbar(key);
      },
    },
  });
};

export default snackbar;
