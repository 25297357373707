import styled from 'styled-components';
import Modal from 'components/Core/Modal';

// eslint-disable-next-line import/prefer-default-export
export const MarketingFormModal = styled(Modal)`
  .modal-container {
    max-width: 600px;
    margin: auto;
    width: 100%;
  }

  .modal-marketing-form--container {
    display: flex;
    max-width: 100%;

    .modal-marketing-form--form-container {
      min-width: 0;
      flex: 7;
      position: relative;
    }
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10;
  }

  &.demo-form-style--5 {
    width: 90% !important;
    max-width: 1196px;
  }

  @media (max-width: 575px) {
    .modal-container {
      margin: 0;
    }
  }
`;
