import React from 'react';
import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomLinearProgress = styled(LinearProgress)(
  ({ height = 6, borderRadius = 3, margin = '8px 0', strokeColor = '#4AB971', noTransition }) => ({
    height,
    borderRadius,
    backgroundColor: '#f5f5f5',
    margin,
    '& .MuiLinearProgress-bar': {
      backgroundColor: strokeColor,
      ...(noTransition && { transition: 'none' }),
    },
  }),
);

const Progress = ({ ...props }) => <CustomLinearProgress variant="determinate" {...props} />;

export default Progress;
