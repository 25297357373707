import React from 'react';

const TaxonomyContext = React.createContext({});

export default TaxonomyContext;

export const WithTaxonomyContext = (WrappedComponent) => (props) =>
  (
    <TaxonomyContext.Consumer>
      {(contextProps) => <WrappedComponent taxonomy={contextProps} {...props} />}
    </TaxonomyContext.Consumer>
  );
