import React, { useState, useRef } from 'react';
import useBreakpointView from 'utils/useBreakpointView';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { STIcon } from 'components/Brand/Icons/STIcon';

import { LightboxWrapperContainer, ImageZoomModal } from './styles';
import './styles.less';

function LightboxWrapper(WrapperComponent) {
  const LIGHTBOX_YES_IMAGE = 'Yes, Image only';
  const LIGHTBOX_YES_IMAGE_DESCRIPTION = 'Yes, Image and Description';

  const WrappedImage = ({ enableLightbox, ...otherProps }) => {
    const [openZoom, setOpenZoom] = useState(false);
    const isMobile = useBreakpointView(['xs', 'sm']);
    const transformRef = useRef();

    const source = otherProps.image?.file?.url || otherProps.image;

    const resetTransform = () => {
      if (transformRef && transformRef.current) {
        transformRef.current.resetTransform();
      }
    };

    if (
      enableLightbox === LIGHTBOX_YES_IMAGE ||
      enableLightbox === LIGHTBOX_YES_IMAGE_DESCRIPTION
    ) {
      const { innerWidth, innerHeight } = useWindowDimensions();
      let imageHeight = '100%';
      if (enableLightbox === LIGHTBOX_YES_IMAGE_DESCRIPTION && otherProps.imageSizes) {
        const { width: originWidth, height: originHeight } = otherProps.imageSizes;
        const imageContainerSize = {
          width: innerWidth > 576 ? (innerWidth - 32) * 0.9 : innerWidth,
          height: innerHeight - 178,
        };
        const calcHeight = imageContainerSize.width * (originHeight / originWidth);
        if (imageContainerSize.height > calcHeight) {
          imageHeight = calcHeight;
        }
      }
      return (
        <>
          <LightboxWrapperContainer onClick={() => setOpenZoom(true)}>
            <div className="image-light-box--wrapper-container">
              <div className="image-light-box--content-container">
                <WrapperComponent {...otherProps} />
                <div className="image-light-box--content-overlay" />
              </div>
              <div className="image-light-box--zoom-icon">
                <STIcon type="icon-image-zoom" />
              </div>
              <div className="image-light-box--wrapper-overlay" />
            </div>
          </LightboxWrapperContainer>

          <ImageZoomModal
            open={openZoom}
            onClose={() => {
              setOpenZoom(false);
              resetTransform();
            }}
            handleClose={() => setOpenZoom(false)}
            className="image-light-box--modal-wrapper"
            backgroundSource={source}
            scroll="paper"
          >
            <TransformWrapper
              className="image-light-box--transform-wrapper"
              ref={transformRef}
              disabled={!isMobile}
            >
              <TransformComponent className="image-light-box--transform-component">
                <div
                  className="image-light-box--modal-container"
                  onClick={() => {
                    if (!isMobile) setOpenZoom(false);
                  }}
                >
                  <div
                    className="image-light-box--image-container"
                    style={{ height: imageHeight }}
                  />
                  {enableLightbox === LIGHTBOX_YES_IMAGE_DESCRIPTION && otherProps.alt && (
                    <p className="image-light-box--description">{otherProps.alt}</p>
                  )}
                </div>
              </TransformComponent>
            </TransformWrapper>
          </ImageZoomModal>
        </>
      );
    }

    return <WrapperComponent {...otherProps} />;
  };

  return WrappedImage;
}

export default LightboxWrapper;
