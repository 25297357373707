import { graphql, useStaticQuery } from 'gatsby';

const useAltForms = () => {
  const { contentfulComponentCollection } = useStaticQuery(graphql`
    query {
      contentfulComponentCollection(contentful_id: { eq: "7IkvPRHAdzEZn69IsRy4t2" }) {
        ...ComponentCollection
      }
    }
  `);

  return contentfulComponentCollection?.components;
};

export default useAltForms;
