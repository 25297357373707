import PropTypes from 'prop-types';

const textPropTypes = {
  color: PropTypes.string,
  size: PropTypes.string, // h1, h2, h3, h4, h5, h6, large, medium, normal, small
  sizeDesktop: PropTypes.string, // px
  sizeMobile: PropTypes.string, // px
  alignDesktop: PropTypes.string, // 'left', 'center', 'right'
  alignMobile: PropTypes.string, // 'left', 'center', 'right'
  lineHeightMobile: PropTypes.string, // px, %
  lineHeightDesktop: PropTypes.string, // px, %
  marginMobile: PropTypes.string, // px
  marginDesktop: PropTypes.string, // px
};

export default textPropTypes;
