import React, { useState, useEffect, useMemo } from 'react';
import MarketoForm from 'components/Form/MarketoForm';
import useDefaultDemoForm from 'hooks/useDefaultDemoForm';

const GlobalDemoForm = () => {
  const form = useDefaultDemoForm();

  const [isHidden, setIsHidden] = useState(true);
  const [formProps, setFormProps] = useState({});

  const demoFormStyles = useMemo(() => ({ display: isHidden ? 'none' : 'block' }), [isHidden]);

  useEffect(() => {
    window.injectSwifty = (targetElementId = '', formProps) => {
      const targetElement =
        document.getElementById(targetElementId) ||
        document.getElementById('hero-featured-content') ||
        document.getElementById('feature-content-(use-only-one)');

      if (targetElement) {
        setIsHidden(false);
        setFormProps(formProps);
        const swifty = document.getElementById('global-demo-form');
        if (swifty) {
          if (targetElement.hasChildNodes()) {
            targetElement.replaceChildren(swifty);
          } else {
            targetElement.appendChild(swifty);
          }
        }
      } else {
        console.error(`Target element with ID "${targetElementId}" not found.`);
      }
    };

    return () => {
      window.injectSwifty = null;
    };
  }, []);

  return isHidden ? null : (
    <div id="global-demo-form" style={demoFormStyles}>
      <MarketoForm inlineMarketingForm={form} {...formProps} />
    </div>
  );
};

export default GlobalDemoForm;
