import styled from 'styled-components';
import { getColor } from 'utils/colorUtility';

const StylesContainer = styled.div`
  ${({ backgroundColor, backgroundImage, customBackgroundDesktop, customBackgroundMobile }) => {
    if (backgroundImage) {
      return `
        background: ${`url(${backgroundImage?.image?.file?.url || backgroundImage})`};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
      `;
    } else if (backgroundColor || customBackgroundDesktop || customBackgroundMobile) {
      return `background: ${
        getColor(backgroundColor) || customBackgroundDesktop || customBackgroundMobile
      };`;
    }
  }};
  ${({ border, borderColor }) => {
    if (border) {
      getColor;
      return `
        border: ${border} solid ${getColor(borderColor) || 'black'}
      `;
    }
  }};
  ${({ heightDesktop, heightMobile }) => {
    if (heightDesktop || heightMobile) {
      return `height: ${heightDesktop || heightMobile}`;
    }
  }};
  ${({ justifyDesktop, justifyMobile }) => {
    if (justifyDesktop || justifyMobile) {
      return `
        justify-content: ${justifyDesktop || justifyMobile};
        align-items: ${justifyDesktop || justifyMobile};
        display: flex;
        flex-direction: column;
        height: 100% !important;
      `;
    }
  }}
  ${({ stickToBottom }) => {
    if (stickToBottom) {
      return `
        position: absolute;
        bottom: 0;
      `;
    }
  }};
  ${({ stickToBottom }) => {
    if (stickToBottom) {
      return `
        position: absolute;
        bottom: 0;
      `;
    }
  }};
  margin: ${({ marginMobile, marginDesktop }) => marginDesktop || marginMobile};
  order: ${({ orderDesktop }) => orderDesktop};
  padding: ${({ paddingMobile, paddingDesktop }) => paddingDesktop || paddingMobile};
  box-shadow: ${({ shadow }) => {
    if (shadow === 'Small') {
      return '0px 3px 10px rgba(11, 26, 33, 0.08);';
    } else if (shadow === 'Medium') {
      return '0px 15px 40px rgba(11, 26, 33, 0.1);';
    } else if (shadow === 'Large') {
      return ' 0px 30px 60px rgba(11, 26, 33, 0.15);';
    }
  }};
  width: ${({ widthDesktop, widthMobile }) => widthDesktop || widthMobile || '100%'};
  max-height: ${({ maxHeightDesktop, maxHeightMobile }) =>
    maxHeightDesktop || maxHeightMobile || 'none'};
  max-width: ${({ maxWidthDesktop, maxWidthMobile }) =>
    maxWidthDesktop || maxWidthMobile || 'none'};
  ${({ $customCss }) => $customCss};

  @media (max-width: 575px) {
    ${({ customBackgroundMobile }) => {
      if (customBackgroundMobile) {
        return `background: ${customBackgroundMobile};`;
      }
    }};
    ${({ heightMobile }) => {
      if (heightMobile) {
        return `height: ${heightMobile}`;
      }
    }};
    ${({ justifyMobile }) => {
      if (justifyMobile) {
        return `
        justify-content: ${justifyMobile};
        align-items: ${justifyMobile};
        display: flex;
        flex-direction: column;
        height: 100% !important;
      `;
      }
    }};
    margin: ${({ marginMobile }) => marginMobile};
    order: ${({ orderMobile }) => orderMobile};
    padding: ${({ paddingMobile }) => paddingMobile};
    position: relative;
    width: ${({ widthMobile }) => widthMobile || '100%'};
    max-height: ${({ maxHeightMobile }) => maxHeightMobile || 'inherit'};
    max-width: ${({ maxWidthMobile }) => maxWidthMobile || 'inherit'};
  }
`;

export { StylesContainer };
