import styled from 'styled-components';
import Modal from 'components/Core/Modal';

const WebsiteImageContainer = styled.div`
  box-shadow: ${({ boxShadow }) => (!!boxShadow ? '0px 15px 40px rgba(11, 26, 33, 0.1);' : 'none')};
  margin: ${({ marginMobile, marginDesktop }) => marginDesktop || marginMobile};
  height: auto;
  width: ${({ widthDesktop, widthMobile, imageSizes }) =>
    widthDesktop
      ? widthDesktop
      : widthMobile
      ? widthMobile
      : imageSizes
      ? `${imageSizes?.width}px`
      : '100%'};
  max-width: 100%;

  @media (max-width: 767px) {
    height: auto;
    width: ${({ widthMobile }) => widthMobile};
  }

  ${({ maxSizingStyles }) => maxSizingStyles}

  .gatsby-image-wrapper {
    box-shadow: ${({ boxShadow }) => boxShadow === false && 'none !important'};
  }
`;

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  width: ${({ widthDesktop, widthMobile }) => widthDesktop || widthMobile};
  height: ${({ heightDesktop, heightMobile }) => heightDesktop || heightMobile || 'auto'};
  margin: ${({ marginDesktop, marginMobile }) => marginDesktop || marginMobile};

  @media (max-width: 767px) {
    width: ${({ widthMobile }) => widthMobile};
    margin: ${({ marginMobile }) => marginMobile};
  }
`;

const LightboxWrapperContainer = styled.div`
  .image-light-box--wrapper-container {
    position: relative;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    cursor: -webkit-zoom-in;
    cursor: zoom-in;

    .image-light-box--content-container {
      position: relative;

      .image-light-box--content-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(99, 121, 132);
        opacity: 0;
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
    }

    .image-light-box--zoom-icon {
      position: absolute;
      bottom: 30px;
      right: 30px;

      svg {
        height: 55px;
        width: 55px;
      }
    }

    .image-light-box--wrapper-overlay {
      background: var(--dark);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &:hover {
      transform: scale(1.04);
      box-shadow: 0px 30px 60px rgb(11 26 33 / 15%);

      .image-light-box--content-container {
        .image-light-box--content-overlay {
          opacity: 0.1;
        }
      }
    }

    @media (max-width: 575px) {
      .image-light-box--zoom-icon {
        bottom: 15px;
        right: 15px;
      }
    }
  }
`;

const ImageZoomModal = styled(Modal)`
  .modal-container {
    width: 100vw;
    background: transparent;
  }

  .image-light-box--modal-container {
    width: 90%;
    height: calc(100% - 100px);
    margin: 50px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 575px) {
      width: 100%;
      max-width: calc(100% - 40px);
    }

    .image-light-box--image-container {
      width: 100%;
      background-image: url(${(props) => props.backgroundSource});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .image-light-box--description {
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-16);
      line-height: var(--line-height-150);
      text-align: center;
      color: var(--white);
      max-width: 930px;
      margin: 30px auto 0;
      padding: 0 15px;

      @media (max-width: 575px) {
        margin: 20px 20px 0;
        padding: 0;
      }
    }
  }
`;

export { WebsiteImageContainer, StyledImage, LightboxWrapperContainer, ImageZoomModal };
