import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Styles from 'components/Styles';
import Video from 'components/Core/Video';
import LightboxWrapper from './LightboxWrapper';
import getMaxSizingStyles from './utils/getMaxSizingStyles';
import getCustomImage from './utils/getImage';
import WithRoute from './utils/WithRoute';

import { WebsiteImageContainer, StyledImage } from './styles';

const WebsiteImage = (props) => {
  const {
    alt,
    boxShadow,
    className,
    fullResolution,
    imageSizes,
    imgStyle,
    imgContainerStyle,
    maxWidth,
    roundedCorners,
    styles: styles1,
    widthDesktop,
    widthMobile,
    heightDesktop,
    heightMobile,
    loading = 'eager',
    templateId,
  } = props;

  const imageToRender = getCustomImage(props);
  const gatsbyImage = getImage(imageToRender);
  const styles = styles1 || props?.image?.styles2;

  const {
    marginMobile,
    marginDesktop,
    maxHeightDesktop,
    maxHeightMobile,
    maxWidthDesktop,
    maxWidthMobile,
  } = styles || {};

  const maxSizingStyles =
    maxHeightDesktop || maxHeightMobile || maxWidthDesktop || maxWidthMobile
      ? getMaxSizingStyles(imageToRender, styles)
      : {};

  if (imageToRender !== null && imageToRender !== undefined && imageToRender !== '') {
    const style = {
      ...imgStyle,
      ...props.style,
      maxWidth,
    };
    if (gatsbyImage) {
      return (
        <Styles {...styles} data-testid={props['data-testid']}>
          <WebsiteImageContainer
            data-optimizely="website-image"
            boxShadow={boxShadow}
            className="website-image"
            imageSizes={imageSizes}
            maxSizingStyles={maxSizingStyles}
            widthDesktop={widthDesktop}
            widthMobile={widthMobile}
            style={imgContainerStyle}
          >
            <GatsbyImage
              loading={loading}
              image={gatsbyImage}
              backgroundColor="transparent"
              alt={alt || 'Product Illustration'}
              imgStyle={style}
              className={className}
              style={style}
              src={gatsbyImage.images?.sources?.[0]?.srcSet}
            />
          </WebsiteImageContainer>
        </Styles>
      );
    }

    if (
      imageToRender?.file?.contentType === 'video/mp4' ||
      imageToRender?.file?.contentType === 'video/x-m4v' ||
      (typeof imageToRender === 'string' && imageToRender?.endsWith('mp4'))
    ) {
      return (
        <Video
          className={className}
          data-testid={props['data-testid']}
          boxShadow={boxShadow && !roundedCorners}
          mainSrc={imageToRender?.file?.url || imageToRender}
          previewSrc={imageToRender?.file?.url || imageToRender}
          roundedCorners={roundedCorners}
          type="preview-only"
        />
      );
    }

    const source = imageToRender?.file?.url || imageToRender?.url || imageToRender;

    return (
      <StyledImage
        data-optimizely="website-image"
        data-testid={props['data-testid']}
        src={source}
        alt={alt || 'Product Illustration'}
        marginMobile={marginMobile}
        marginDesktop={marginDesktop}
        widthMobile={widthMobile}
        widthDesktop={widthDesktop}
        heightMobile={heightMobile}
        heightDesktop={heightDesktop}
        className={`styled-image ${className} ${templateId}`}
        style={style}
        loading={loading}
      />
    );
  }

  return null;
};

export default WithRoute(LightboxWrapper(WebsiteImage));
