import axios from 'axios';

export const formSubmittedEvent = (obj) => {
  window.dataLayer = window.dataLayer || [];
  const pageTitle =
    document.querySelector("meta[property='og:title']")?.getAttribute('content') || '';
  window.dataLayer.push({
    event: 'form submitted',
    page_title: pageTitle,
    url: window?.location?.href || '',
    ...obj,
  });
};

export const potentialFraudEvent = async (obj) => {
  window.dataLayer = window.dataLayer || [];

  const {
    company,
    Company,
    email,
    Email,
    firstName,
    FirstName,
    lastName,
    LastName,
    Phone,
    mobilePhone,
    utm_source: utmSource,
  } = obj;

  const response = await axios.get('https://geolocation-db.com/json/');
  const ipAddress = response?.data?.IPv4;

  window.dataLayer.push({
    event: 'potential fraud',
    company: Company || company,
    email: Email || email,
    firstName: FirstName || firstName,
    lastName: LastName || lastName,
    mobilePhone: Phone || mobilePhone,
    url: window.location.href,
    utm_source: utmSource,
    ipAddress,
  });
};

export const updatePageLoaded = (pageType, taxonomy) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'page-loaded',
    page_type: pageType,
    ...taxonomy,
  });
};

export const updateAudience = (audienceData) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ audience: audienceData || 'Prospect' });
};

export const navigationEvent = (ctaText, url, ctaPosition) => {
  dataLayer.push({
    event: 'cta clicked',
    cta_text: ctaText,
    cta_position: ctaPosition || 'Header - Menu Item CTA',
    cta_url: url,
  });
};
