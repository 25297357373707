import { useState, useEffect } from 'react';
import useIsClient from './useIsClient';

const useBreakpoint = () => {
  const isClient = useIsClient();

  const breakpoints = [
    { label: 'xs', width: 480 },
    { label: 'sm', width: 576 },
    { label: 'md', width: 768 },
    { label: 'lg', width: 992 },
    { label: 'xl', width: 1200 },
    { label: 'xxl', width: 1600 },
  ];

  const getBreakpoint = (width) => {
    const brk = breakpoints.find((breakpoint, index) => {
      if (index === 0 && width < breakpoints[0].width) return breakpoint;
      if (index === 5 && width > breakpoints[5].width) return breakpoint;
      if (width >= breakpoint.width && width < breakpoints?.[index + 1]?.width) return breakpoint;
      return null;
    });

    return brk?.label;
  };

  const currWindow = typeof window !== 'undefined' ? window : null;

  const [width, setWidth] = useState(currWindow?.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(currWindow?.innerWidth);
    currWindow?.addEventListener('resize', handleWindowResize);

    return () => currWindow?.removeEventListener('resize', handleWindowResize);
  }, []);

  const breakpoint = getBreakpoint(width);

  return isClient ? { width, breakpoint } : { breakpoint: null, width: null };
};

export default useBreakpoint;
