import styled, { css } from 'styled-components';

export const FormContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.backgroundColor || 'white'};
  box-shadow: ${(props) => (props.shadow ? 'rgba(160,161,188,.35)' : 'none')};

  .signup-form--form {
    .data-layer-form {
      padding: 33px 30px 0;
    }

    .signup-form--mobile-header,
    .signup-form--other-industry-wrapper {
      display: none;
    }

    .checkboxes-field-wrapper,
    .radio-field-wrapper {
      flex-direction: row;
      flex-wrap: wrap;
      height: inherit;
    }

    .checkboxes-div,
    .radio-div {
      width: 100%;
      height: auto;
      margin: 9px;
      color: var(--darkest);
      float: none;
      flex: 45%;

      &:last-child label {
        border-left: 1px solid var(--grey-4);
      }

      &.selected:last-child label {
        border-left: 2px solid var(--titan-blue-3);
      }

      &.selected label {
        border-width: 3px;
      }

      label {
        padding: 24px 16px;
        line-height: 30px;
        font-size: var(--font-size-16);
      }
    }

    .checkboxes-field-error .checkboxes-div > label,
    .radio-field-error .radio-div > label {
      border-left: 3px solid red;
    }

    .st-slider-field--helper {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      display: inline-block;
      border-radius: 50%;
      background: var(--titan-blue-4);
      font-weight: var(--font-weight-700);
      font-size: var(--font-size-13);
      line-height: 22px;
      text-align: center;
      color: var(--white);
      margin-left: 10px;
      cursor: pointer;
      vertical-align: top;
    }

    .signup-form--description-normal {
      display: none;
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-16);
      line-height: var(--line-height-150);
      letter-spacing: 0;
      color: var(--dark);
      text-align: center;
      margin: 10px 0 20px 0;
    }

    .signup-form--description-tooltip {
      margin-top: 7px;
    }

    .signup-form--progress {
      width: 100%;
      padding: 0 40px 33px;
      margin: 0 auto;
    }

    .signup-form--footer {
      padding: 0 40px 33px;

      p {
        margin: 0;
      }
      ${(props) =>
        !props.backgroundColor ||
        (props.backgroundColor !== '#ffffff' &&
          props.backgroundColor !== 'var(--white)' &&
          `
          p {
            color: var(--white) !important;
          }
          a {
            color: var(--white) !important;
            &:hover {
              text-decoration-color: var(--white) !important;
            }
          }
      `)}
    }

    .signup-form--footer-1 {
      padding: 0 40px 33px;

      p {
        margin: 0;
      }

      a {
        text-decoration: underline;
      }
    }

    .slick-prev {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 5px;
      padding: 0;

      &:before {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 9px;
        content: '';
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
    }

    .signup-form--hp123-item {
      position: relative;
      overflow: hidden;
      margin: 0;
      width: 0;
      & > div {
        position: absolute;
        top: -2000px;
      }
    }

    &.show-other--industry {
      .signup-form--industry-wrapper {
        display: none;
      }

      .signup-form--other-industry-wrapper {
        display: block;
        padding-bottom: 10px;

        .radio-div {
          flex: 100%;
          height: 40px;
          margin: 5px 9px;
          max-width: calc(100% - 33px);

          label {
            font-size: var(--font-size-16);
            padding: 0;
            height: 100%;
          }
        }

        .signup-form--other-industry-search {
          margin: 25px 9px 9px;
          width: calc(100% - 18px);
          height: 58px;
          position: relative;

          input {
            font-weight: var(--font-weight-500);
            font-size: var(--font-size-20);
            line-height: var(--line-height-150);
            padding: 14px 18px;
            color: var(--darkest);

            &::placeholder {
              color: var(--grey-6);
            }
          }

          .signup-form--other-industry-close {
            width: 34px;
            height: 34px;
            position: absolute;
            right: 8px;
            top: 12px;
            padding: 12px;
            cursor: pointer;
          }
        }
      }

      .signup-form--footer-1 {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .signup-form--form {
      .data-layer-form {
        padding: 24px 16px 0;
      }

      .signup-form--footer,
      .signup-form--footer-1,
      .signup-form--progress {
        padding: 0 16px 24px;
      }

      .form-field {
        button {
          margin-top: 0;
        }
      }

      .signup-form--title {
        margin-bottom: 20px;
      }

      .signup-form--footer,
      .signup-form--footer-1 {
        font-size: var(--font-size-13) !important;
      }

      .signup-form--description-tooltip {
        margin-top: 2px;
      }

      .slick-prev {
        top: 18px;
        &:before {
          padding: 7px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    height: 100vh;

    .signup-form--form {
      .data-layer-form {
        padding: 20px 20px 0;
      }

      .signup-form--progress {
        display: none;
      }

      .signup-form--mobile-header {
        display: block;
        position: relative;
        margin-top: 18px;

        .slick-prev {
          position: absolute;
          left: 20px;

          &:before {
            padding: 7px;
          }
        }

        .signup-form--progress {
          display: block;
          padding: 4px 88px;
        }
      }

      .signup-form--footer,
      .signup-form--footer-1 {
        font-size: var(--font-size-13) !important;
        line-height: var(--line-height-150);
        color: var(--dark);
        padding: 0 20px 20px;
      }

      .signup-form--title {
        margin-bottom: 15px;
      }

      .signup-form--step1 {
        .signup-form--title {
          margin-right: inherit;
          margin-bottom: 0;
          padding-bottom: 20px;
        }
      }

      .form-field {
        margin-bottom: 16px;
      }

      .signup-form--step2,
      .signup-form--step3,
      .signup-form--step4 {
        .form-field {
          margin-bottom: 13px;
        }
      }

      .signup-form--description-tooltip {
        display: none;
      }

      .signup-form--submit-button,
      .signup-form--submit-button-final {
        padding: 12px 24px;
        font-size: var(--font-size-16);
        width: 100%;
        height: 48px;
        box-shadow: none;
        margin-top: 0 !important;
      }

      .checkboxes-field-wrapper,
      .radio-field-wrapper {
        width: calc(100% + 10px);
      }

      .checkboxes-div,
      .radio-div {
        flex: 40%;
        margin: 5px;

        label {
          font-size: var(--font-size-16);
          line-height: var(--line-height-150);
          padding: 16px 8px;
          height: 64px;
        }
      }

      .section-demo-hero--mobile-form-content {
        overflow: auto;
      }

      &.show-other--industry {
        .signup-form--other-industry-wrapper {
          .radio-field-wrapper {
            width: 100%;
          }

          .signup-form--other-industry-search {
            margin-left: 4px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  &.demo-form-style--5 {
    .signup-form--step1 {
      background: var(--titan-blue-3);

      .signup-form--title {
        color: var(--white);
      }
    }

    .signup-form--description-tooltip {
      display: none;
    }

    .signup-form--description-normal {
      display: block;
    }

    .signup-form--footer {
      background: var(--titan-blue-3);
      font-size: var(--font-size-16);
      font-weight: var(--font-weight-300);
      line-height: 26px;
      color: var(--white);
      text-align: center;
      & > a {
        color: var(--white);
        text-decoration: underline;
      }
    }

    .signup-form--footer-1 {
      display: none;
    }

    .signup-form--progress {
      width: 90%;
    }

    .signup-form--submit-button-final {
      margin: 15px calc(15% + 8px);
      width: calc(70% - 16px);
    }

    .checkboxes-div,
    .radio-div {
      height: 90px;
      flex: 30%;
    }

    .checkboxes-field-wrapper,
    .radio-field-wrapper {
      margin-top: 20px;
    }

    #officeStaffNumber,
    #areaOfFocus,
    #typeOfBusiness {
      padding: 0 15%;

      .checkboxes-div,
      .radio-div {
        flex: 45%;
      }
    }

    @media (max-width: 575px) {
      .checkboxes-div,
      .radio-div {
        flex: 45%;
        height: auto;
      }

      .signup-form--footer {
        text-align: left;
      }

      .signup-form--progress {
        width: 100%;
      }

      #officeStaffNumber,
      #areaOfFocus,
      #typeOfBusiness {
        padding: 0;
      }

      .signup-form--submit-button-final {
        margin: 15px 0;
        width: 100%;
      }
    }
  }
`;

export const StepContainer = styled.div`
  min-height: 600px;

  @media (max-width: 575px) {
    min-height: 400px;
  }
`;

export const FormTitleStyles = css`
  width: 100%;
  font-family: var(--font-family-heading);
  font-size: ${(props) => props.titleSize || 'var(--font-size-h3)'};
  font-weight: var(--font-weight-700);
  letter-spacing: var(--letter-spacing-h3);
  line-height: var(--line-height-110);
  text-align: center;
  padding: 0 30px;
  margin: 0 0 24px;
  color: ${(props) => props.titleColor || 'var(--darkest)'};

  @media (min-width: 1200px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    font-size: var(--font-size-h4);
  }

  @media (max-width: 767px) {
    font-size: var(--font-size-mobile-h5);
    letter-spacing: var(--letter-spacing-mobile-h5);
    line-height: 26px;
    margin: 0;
  }
`;

export const FormTitle = styled.p`
  ${FormTitleStyles}
`;
