import isNil from 'utils/isNil';

const extractValue = (object, keyToFind, keyToRemove) => {
  let value;

  const traverse = (o) => {
    if (typeof o === 'object') {
      const keys = Object.keys(o);

      keys.forEach((key) => {
        if (key === keyToFind) value = o[key];
        if (!!o[key] && typeof o[key] === 'object' && !value) {
          traverse(o[key]);
        }
      });
    }
  };

  traverse(object);

  if (value?.[keyToRemove] !== undefined) {
    return value[keyToRemove];
  }

  return value;
};

export const getValue = (
  object,
  config = { key: null, parentKey: null },
  keyToRemove = 'en-US',
) => {
  let key;
  let parentKey;

  if (config.key) {
    key = config.key;
    parentKey = config.parentKey;
  } else {
    key = config;
  }

  let usableObject = { ...object };

  if (parentKey) {
    usableObject = extractValue(object, parentKey, keyToRemove);
  }

  return extractValue(usableObject, key, keyToRemove);
};

export const flattenRichText = (obj, deleteKey = 'en-US') => {
  const object = { ...obj };

  const removeByKey = (myObj) => {
    const replaceObjectValues = (objForReplacement, keyToReplace) => {
      Object.keys(objForReplacement).forEach((key) => {
        const value = objForReplacement[key];

        if (!isNil(value?.[keyToReplace])) {
          objForReplacement[key] = value[keyToReplace];
        }

        if (
          (typeof objForReplacement[key] === 'object' || Array.isArray(objForReplacement[key])) &&
          !isNil(objForReplacement[key])
        ) {
          removeByKey(objForReplacement[key]);
        }
      });
    };

    if (Array.isArray(myObj)) {
      myObj.forEach((obj2) => {
        replaceObjectValues(obj2, deleteKey);
      });
    } else {
      replaceObjectValues(myObj, deleteKey);
    }

    return myObj;
  };

  return removeByKey(object, deleteKey);
};

export const separateScriptTags = (htmlStr) => {
  const scriptRegEx = /<script\b[^>]*>([\s\S]+?)<\/script>/gi;
  const html = htmlStr?.replace(/<script\b[^>]*>([\s\S]+?)<\/script>/gi, '');

  const js = htmlStr?.match(scriptRegEx)?.map((val) => val.replace(/<\/?script\b[^>]*>/gi, ''));

  return {
    js,
    html,
  };
};

export const getRichTextValue = (richTextEntry) =>
  JSON.parse(richTextEntry?.content?.raw)?.content[0]?.content[0]?.value;
