import React from 'react';
import HyperLink from 'components/Core/HyperLink';

const WithRoute = (WrappedComponent) => (props) => {
  const { contentfulUrl } = props || {};

  return contentfulUrl ? (
    <HyperLink href={contentfulUrl}>
      <WrappedComponent {...props} />
    </HyperLink>
  ) : (
    <WrappedComponent {...props} />
  );
};

export default WithRoute;
