import { graphql, useStaticQuery } from 'gatsby';

const useIndustries = (industryInclusionList = [], industryExclusionList = []) => {
  const { allContentfulIndustry, allContentfulIndustryOther } = useStaticQuery(graphql`
    {
      allContentfulIndustry(
        filter: { isPrimary: { eq: "Yes" }, excludeForms: { ne: true } }
        sort: [{ order: ASC }, { name: ASC }]
      ) {
        nodes {
          ...Industry
          industrySpecificTechnicianName
        }
      }
      allContentfulIndustryOther: allContentfulIndustry(
        filter: { isPrimary: { ne: "Yes" }, excludeForms: { ne: true } }
        sort: [{ order: DESC }, { name: ASC }]
      ) {
        nodes {
          ...Industry
          industrySpecificTechnicianName
        }
      }
    }
  `);

  const configOption = (optionList) =>
    optionList.map((node) => ({
      label: node.name,
      value: node.value || node.name,
      isPrimary: node.isPrimary,
      ...(node.industrySpecificTechnicianName
        ? { industrySpecificTechnicianName: node.industrySpecificTechnicianName }
        : {}),
    }));

  const excludeIndustries = (listToFilter, filterList) =>
    listToFilter.filter(
      (industry) =>
        !filterList.find(
          (x) =>
            x.value === industry.value &&
            x.label === industry.label &&
            x.isPrimary === industry.isPrimary,
        ),
    );

  const compare = (a, b, prop) => {
    return a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0;
  };

  const industryList = configOption(
    [...allContentfulIndustry.nodes, ...industryInclusionList.filter((x) => x.isPrimary === 'Yes')]
      .sort((a, b) => compare(a, b, 'name'))
      .sort((a, b) => compare(a, b, 'order')),
  );

  const otherIndustryList = configOption(
    [
      ...allContentfulIndustryOther.nodes,
      ...industryInclusionList.filter((x) => x.isPrimary === 'No'),
    ]
      .sort((a, b) => compare(a, b, 'name'))
      .sort((a, b) => compare(a, b, 'order')),
  );

  const allIndustriesList = [
    ...industryList,
    ...otherIndustryList,
    ...configOption(industryInclusionList),
  ];

  return {
    industryList: excludeIndustries(industryList, configOption(industryExclusionList)),
    otherIndustryList: excludeIndustries(otherIndustryList, configOption(industryExclusionList)),
    allIndustriesList: excludeIndustries(allIndustriesList, configOption(industryExclusionList)),
  };
};

export default useIndustries;
