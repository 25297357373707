import styled from 'styled-components';

const SwiftyFormContainer = styled.div`
  input,
  textarea,
  select,
  button {
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  height: 100%;
  .dynamic-form--container {
    height: 100%;
  }
  .MuiLinearProgress-root {
    display: none;
  }
  .slick-slide {
    visibility: hidden !important;
  }
  .slick-active {
    visibility: visible !important;
  }
  .signup-form--other-industry-radio {
    height: 600px !important;
    max-height: 600px !important;
    padding-bottom: 0 !important;
  }
  div[data-index='0'] {
    .form-field {
      &:nth-last-child(4) {
        flex-direction: column;
        &::before {
          content: 'What kind of jobs does your company focus on?';
          width: 100%;
          margin-bottom: 20px;
          color: var(--grey-8);
          font-size: var(--font-size-16);
          font-weight: var(--font-weight-600);
          font-family: var(--font-family-heading);
          line-height: 23px;
          @media (max-width: 575px) {
            font-size: var(--font-size-13);
          }
        }
      }
      &:nth-last-child(3),
      &:nth-last-child(4) {
        .radio-field--title {
          font-size: var(--font-size-13) !important;
        }
      }
    }
  }
  .signup-form--footer p {
    font-size: var(--font-size-13) !important;
    color: var(--grey-8) !important;
  }

  @media (max-width: 575px) {
    .signup-form--header {
      padding: 20px 20px 10px !important;
    }
    .signup-form--form .data-layer-form {
      padding: 0px 20px 0px;
    }
    .signup-form--footer {
      padding: 0px 20px 20px;
    }
    .dynamic-form--container {
      padding: 0 !important;
    }
    .radio-div {
      label {
        padding: 5px;
      }
      &.selected label {
        padding: 3px;
      }
    }
    .radio-field--title {
      font-size: var(--font-size-13) !important;
    }
  }
`;

export default SwiftyFormContainer;
