/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Modal from 'components/Core/Modal';

export const OtherOptionsContainer = styled.div`
  ${(props) => (!props.visible ? 'display:none' : '')};
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: white;
  position: absolute;
  z-index: var(--z-mui-tooltip);
  top: 0;
  left: 0;
  padding: 33px 30px 0;

  .radio-field--group .radio-field-wrapper {
    flex-wrap: nowrap !important;
  }
  .signup-form--other-industry-radio {
    .radio-field-wrapper {
      padding-bottom: 60px;
      .radio-div label {
        font-size: var(--font-size-20);
        @media (max-width: 575px) {
          font-size: var(--font-size-16);
        }
      }
    }
  }
  .signup-form--other-industry-header .slick-prev {
    position: absolute !important;
    margin-top: 0 !important;
    &::before {
      padding: 9px !important;
      @media (max-width: 767px) {
        padding: 7px !important;
      }
    }
  }

  @media (max-width: 575px) {
    position: fixed;
    bottom: 0;
    z-index: var(--z-modal-select);
  }
`;

export const OtherOptionsModal = styled(Modal)``;
