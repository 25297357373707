import { navigate } from 'gatsby';

const runDriftFastLaneTimeout = (url) => {
  let count = 0;

  const checkDriftFastLane = (driftEle) =>
    setTimeout(() => {
      const drift = document
        .getElementById('drift-frame-chat')
        ?.classList.contains('drift-frame-chat__takeover');

      // Drift fastlane no longer in frame. end.
      if (!drift) return;

      if (count > 9) {
        // Hiding drift fastlane
        if (typeof window !== 'undefined') {
          window.drift?.api.toggleChat();
          navigate(url);
        }
      } else {
        // Drift fastlane still open
        count += 1;
        checkDriftFastLane(driftEle);
      }
    }, 60000);

  setTimeout(() => {
    checkDriftFastLane();
  }, 3000);
};

export default runDriftFastLaneTimeout;
