import React, { useRef, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import _debounce from 'lodash.debounce';
import { isInternalLink, formatInternalLink } from 'utils/urlHelper';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import getButtonStyles from './utils/getButtonStyles';
import {
  StyledButton,
  StyledLink,
  StyledButtonWithoutRouting,
  StyledLinkWithoutRouting,
  btnStyles,
} from './styles';

const Button = ({
  button,
  children,
  className,
  ctaPosition,
  dataCy,
  dataPosition,
  disabled,
  height,
  margin,
  onClick,
  onDebouncedClick,
  padding,
  rel,
  shadow,
  size,
  style,
  styles,
  templateId,
  to,
  type,
  width,
  outlined,
  id,
  target,
  noSubmit,
  useCtaParentRouting,
}) => {
  const ref = useRef();
  const rippleRef = useRef();

  const isLinkInternal = isInternalLink(to);
  const url = isLinkInternal ? formatInternalLink(to) : to;
  const isButton = onClick || onDebouncedClick || button;
  const $buttonType = getButtonStyles(type);
  const { marginDesktop, marginMobile, orderDesktop, orderMobile, customStyling } = styles || {};
  const INTERVAL = 500;

  const onRippleStart = (e) => {
    rippleRef?.current?.start(e);
  };

  const onRippleStop = (e) => {
    rippleRef?.current?.stop(e);
  };

  const handleClick = (e, eventHandler) => {
    const { current } = ref;
    if (current) {
      let temp = current;
      while (
        temp &&
        !temp?.id?.startsWith('section-') &&
        temp.parentNode?.tagName !== 'MAIN' &&
        !temp?.classList?.value?.includes('header-container') &&
        !temp?.classList?.value?.includes('modal-marketing-form--modal')
      ) {
        temp = temp.parentNode;
      }
      if (temp) {
        dataLayer.push({
          event: 'cta clicked',
          label: ref?.current?.textContent || 'none',
          cta_text: ref?.current?.textContent || 'none',
          cta_position: ctaPosition || temp.id || temp?.classList?.value,
          cta_url: url || 'none',
          dataPosition,
        });
      }
    }
    if (typeof eventHandler === 'function') eventHandler(e);
  };

  const onDebouncedClickHandler = useCallback(
    _debounce(
      (e, eventHandler) => {
        handleClick(e, eventHandler);
      },
      INTERVAL,
      { leading: true, trailing: false, maxWait: INTERVAL },
    ),
    [],
  );

  const onClickHandler = (e) => {
    if (onClick) {
      handleClick(e, onClick);
    }
    if (onDebouncedClick) {
      onDebouncedClickHandler(e, onDebouncedClick);
    }
  };

  const onTouchHandler = (e) => {
    if (url) return;
    e.preventDefault();
    if (!disabled) {
      onClickHandler(e);
    }
  };

  const sharedProps = {
    ref,
    className,
    disabled,
    height,
    margin,
    $marginMobile: marginMobile,
    $marginDesktop: marginDesktop,
    $orderDesktop: orderDesktop,
    $orderMobile: orderMobile,
    onClick: onClickHandler,
    onTouchEnd: onTouchHandler,
    padding,
    rel,
    size,
    style,
    $buttonType,
    width,
    styles,
    $outlined: outlined,
    $customCss: customStyling?.customStyling,
    id,
    shadow,
    role: 'button',
    'data-cy': dataCy,
    'data-testid': id && `button-${id}`,
    'data-position': dataPosition,
    'data-optimizely': `button${id ? `-${id}` : ''}`,
    onMouseDown: onRippleStart,
    onMouseUp: onRippleStop,
    onMouseLeave: onRippleStop,
    onDragEnd: onRippleStop,
    onContextMenu: onRippleStop,
  };

  const linkProps = {
    href: url,
    ...sharedProps,
    ...(isLinkInternal ? { shadow: (!!isButton).toString() } : { target: target || '_blank' }),
    className: twMerge(
      btnStyles.base,
      btnStyles[$buttonType],
      btnStyles.anchor,
      className,
      isLinkInternal ? 'button--link' : 'button--atag',
      templateId,
    ),
  };

  const buttonProps = {
    ...sharedProps,
    type: noSubmit ? 'button' : 'submit',
    className: twMerge(
      btnStyles.base,
      btnStyles[$buttonType],
      shadow ? btnStyles.shadow : '',
      'button--button',
      templateId,
      className || '',
    ),
    shadow,
  };
  if (isButton && !url) {
    if (useCtaParentRouting) {
      <StyledButtonWithoutRouting {...buttonProps}>{children}</StyledButtonWithoutRouting>;
    }

    return (
      <StyledButton {...buttonProps}>
        {children}
        <TouchRipple ref={rippleRef} center={false} />
      </StyledButton>
    );
  }

  if (useCtaParentRouting) {
    return (
      <StyledLinkWithoutRouting {...linkProps}>
        {children}
        <TouchRipple ref={rippleRef} center={false} />
      </StyledLinkWithoutRouting>
    );
  }

  return (
    <StyledLink {...linkProps}>
      {children}
      <TouchRipple ref={rippleRef} center={false} />
    </StyledLink>
  );
};

Button.propTypes = {
  type: PropTypes.string, // 'primary', 'secondary', 'tertiary', 'white', 'ghost', 'transparent - black border'
  size: PropTypes.string, // 'big', 'small'
  shadow: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  outlined: PropTypes.bool,
};

Button.defaultProps = {
  type: 'primary',
  size: 'big',
  shadow: true,
  padding: '16px 24px',
  outlined: false,
};

export default Button;
