export const DEFAULT_ICON_MAP = {
  'icon-play': { width: '15', height: '21' },
  'icon-pause': { width: '30', height: '30' },
  'icon-graph-horizontal': { width: '25', height: '26' },
  'icon-graph-circle': { width: '26', height: '26' },
  'icon-success': { width: '24', height: '17' },
  'icon-success-green': { width: '81', height: '80' },
  'icon-table-tick': { width: '24', height: '18' },
  'icon-table-untick': { width: '18', height: '18' },
  'icon-white-tick': { width: '24', height: '18' },
  'icon-category': { width: '11', height: '11' },
  'icon-close-x': { width: '22', height: '22' },
  'icon-modal-close': { width: '15', height: '15' },
  'icon-search': { width: '25', height: '26' },
  'icon-location': { width: '24', height: '25' },
  'icon-briefcase': { width: '24', height: '25' },
  'icon-select-arrow': { width: '12px', height: '12px' },
  'icon-arrow-right': {},
  'icon-image-zoom': { alt: 'Zoom Lightbox Icon' },
  'icon-slider-arrow-left': { width: '21', height: '33' },
  'icon-color-wheel': { width: '47', height: '64' },
  'icon-retake': { width: '20', height: '20' },
  'icon-increase': { width: '17', height: '15' },
  'icon-url': { width: '25', height: '25' },
  'icon-calendar': { width: '14', height: '14' },
  'icon-timer': { width: '14', height: '14' },
  'icon-live': { width: '23', height: '14' },
  'icon-email': { width: '80', height: '60' },
  'icon-play-small': { width: '20', height: '21' },
  'icon-arrow-right1': { width: '18', height: '14' },
  'icon-arrow-left': { width: '15', height: '12' },
  'icon-slider-arrow-right': { width: '21', height: '33' },
  'toolbox-logout': { width: '24', height: '24' },
  'icon-pagination-arrow-next': { width: '9', height: '14' },
  'icon-selected': { width: '30', height: '30' },
  'icon-linkedin': { width: '30', height: '27' },
  'icon-instagram': { width: '20', height: '27' },
  'icon-facebook': { width: '12', height: '29' },
  'icon-youtube': { width: '30', height: '30' },
  'icon-x': { width: '22', height: '22' },
  'icon-filter': { width: '19', height: '32' },
  'icon-appstore': { width: '25', height: '25' },
  'icon-bars': { width: '25', height: '25' },
  'icon-windows': { width: '20', height: '20' },
  'icon-yahoo': { width: '20', height: '20' },
  'icon-google': { width: '20', height: '20' },
  'icon-apple': { width: '20', height: '20' },
  'icon-trash': { width: '20', height: '20' },
  'icon-close': { width: '20', height: '20' },
  'icon-caret': { width: '16', height: '16' },
  'icon-up-caret': { width: '16', height: '16' },
  'icon-down-caret': { width: '16', height: '16' },
  'icon-up-caret-filled': { width: '12', height: '12' },
  'icon-down-caret-filled': { width: '12', height: '12' },
  'icon-check': { width: '14', height: '14' },
  'icon-camera': { width: '18', height: '18' },
  'icon-codepen': { width: '18', height: '18' },
  'icon-layout': { width: '18', height: '18' },
  'icon-idcard': { width: '18', height: '18' },
  'icon-info-circle': { width: '14', height: '14', fill: '#c4c4c4' },
  'icon-download': { width: '14', height: '14', fill: '#c4c4c4' },
  'icon-reload-outline': { width: '14', height: '14', fill: '#c4c4c4' },
  warning_icon: { width: '12', height: '12' },
  clock: { width: '14', height: '14' },
  user: { width: '12', height: '12' },
  glasses: { width: '28', height: '14' },
  mail: { width: '60', height: '46' },
  blue_check: { width: '24', height: '17' },
  'st-glyph': {},
  'st-glyph-tertiary': {},
  'st-logo': {},
  'st-logo-tertiary': {},
  'st-logo-commercial': {},
  'st-logo-tertiary-commercial': {},
  'check-circle': { width: '24', height: '24' },
  'checkbox-blank-circle-outline': { width: '24', height: '24' },
  'close-circle-outline': {},
  prefilledCheck: { width: '24', height: '24' },
  'left-arrow-circle': { width: '45', height: '45' },
  infolink: { width: '24', height: '24' },
};
