import React from 'react';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import './styles.less';

const StyledTooltip = styled(({ className, tooltipStyle, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ tooltipStyle }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--white)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--white)',
    boxShadow: 'var(--shadow-large)',
    color: 'var(--black)',
    fontSize: 'var(--font-size-p-sm)',
    padding: 15,
    ...tooltipStyle,
  },
}));

const Tooltip = ({ className, children, placement = 'top', title, open, tooltipStyle, dataCy }) => {
  return (
    <StyledTooltip
      PopperProps={{ dataCy }}
      className={className}
      placement={placement || 'top'}
      arrow
      title={title}
      open={open}
      tooltipStyle={tooltipStyle}
      enterTouchDelay={0}
    >
      <span
        data-cy="tooltip"
        className={`st-slider-field--helper ${className || ''} ${placement || ''}`}
      >
        {children || '?'}
      </span>
    </StyledTooltip>
  );
};

export default Tooltip;
