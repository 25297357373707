import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { STIcon } from 'components/Brand/Icons/STIcon';
import { VideoContainer } from './styles';

const Video = ({
  className,
  boxShadow,
  buttonColor,
  buttonLabel,
  height,
  mainSrc,
  previewSrc,
  roundedCorners,
  type,
  width,
  ...otherProps
}) => {
  const [isPlaying, setPlaying] = useState(false);

  const mainVideo = createRef();
  const loopVideo = createRef();

  const onPlayButtonClick = () => {
    setPlaying(true);
    mainVideo.current.play();
    loopVideo.current.pause();
  };

  const onBackDropClick = () => {
    setPlaying(false);
    mainVideo.current.pause();
  };

  const onMainVideoEnded = () => {
    setPlaying(false);
    loopVideo.current.load();
  };

  return (
    <VideoContainer
      className={`video-main-container video-type-${type} ${boxShadow ? 'video-box-shadow' : ''} ${
        roundedCorners ? 'video-rounded-corners' : ''
      } ${className}`}
      width={width || '100%'}
      {...otherProps}
    >
      {type !== 'preview-only' && (type === 'normal' || type === 'button') && (
        <button
          type="button"
          className={`video-play-button${!isPlaying ? ' video-play-button--visible' : ''}`}
          onClick={onPlayButtonClick}
          aria-label="Play Video"
        >
          <span className="video-play-button-icon">
            <STIcon type="icon-play" style={{ fill: 'white', width: '21px', height: '17px' }} />
          </span>
        </button>
      )}
      {type !== 'preview-only' && type === 'button-label' && (
        <button type="button" className="video-play-button-label" onClick={onPlayButtonClick}>
          <span className="video-play-button-icon" style={{ color: buttonColor }}>
            <STIcon type="icon-play" style={{ fill: buttonColor, width: '21px', height: '21px' }} />
            {buttonLabel}
          </span>
        </button>
      )}
      <div
        className={`video-preview-container${
          type !== 'normal' && type !== 'preview-only' ? ' video-preview-container--hide' : ''
        }`}
      >
        <video className="video-preview-video" ref={loopVideo} muted playsInline autoPlay loop>
          <source src={previewSrc} type="video/mp4" />
        </video>
      </div>

      {type !== 'preview-only' && (
        <div
          className={`video-mainview-container${
            isPlaying ? ' video-mainview-container--visible' : ''
          }`}
        >
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                onBackDropClick();
              }
            }}
            className={`video-main-video-container${
              isPlaying ? ' video-main-video-container--visible' : ''
            }`}
            onClick={onBackDropClick}
            aria-label="Play/Pause Video"
          >
            <video
              className="video-main-video"
              ref={mainVideo}
              controls={isPlaying ? 'controls' : ''}
              onEnded={onMainVideoEnded}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <source src={mainSrc} type="video/mp4" />
              <track kind="captions" />
            </video>
          </div>
        </div>
      )}
    </VideoContainer>
  );
};

Video.propTypes = {
  type: PropTypes.string, // 'normal', 'preview-only', 'button'(play button only), 'button-label'(simple play button with button label)
  width: PropTypes.string,
  height: PropTypes.string,
  mainSrc: PropTypes.string,
  previewSrc: PropTypes.string,
  buttonLabel: PropTypes.string, // used only when the type is button-label
  buttonColor: PropTypes.string, // used only when the type is button-label
};

Video.defaultProps = {
  type: 'normal',
  buttonLabel: 'Watch Video',
  buttonColor: 'white',
};

export default Video;
