import useBreakpointView from 'utils/useBreakpointView';

const getImage = (props) => {
  const isMobile = useBreakpointView(['xs']);
  const isTablet = useBreakpointView(['md', 'sm']);
  const {
    bynderDesktopImage,
    bynderMobileImage,
    bynderTabletImage,
    image,
    imageMobile,
    imageTablet,
    url,
  } = (props?.image?.image || props?.image?.bynderDesktopImage ? props.image : props) || {};

  const mobileImage =
    bynderMobileImage?.[0]?.thumbnails?.src ||
    bynderMobileImage?.[0]?.videoPreviewURLs?.[0] ||
    imageMobile;

  const tabletImage =
    bynderTabletImage?.[0]?.thumbnails?.src ||
    bynderTabletImage?.[0]?.videoPreviewURLs?.[0] ||
    imageTablet;

  const desktopImage =
    bynderDesktopImage?.[0]?.thumbnails?.src ||
    bynderDesktopImage?.[0]?.videoPreviewURLs?.[0] ||
    image;

  if (isMobile === null && isTablet === null) return null;
  if (isMobile) return mobileImage || tabletImage || desktopImage || url;
  if (isTablet) return tabletImage || desktopImage || mobileImage || url;
  return desktopImage || tabletImage || mobileImage || url;
};

export default getImage;
