const removeOptinmonsterClasses = () => {
  const bodyClass = document.body.className;
  const htmlClass = document.documentElement.className;

  if (bodyClass.includes('om-effect-overlay')) {
    const newBodyClass = bodyClass.replace('om-effect-overlay', '');
    document.body.className = newBodyClass;
  }

  if (htmlClass.includes('om-position-popup')) {
    const newHtmlClass = htmlClass.replace('om-position-popup', '');
    document.documentElement.className = newHtmlClass;
  }

  if (htmlClass.includes('om-position-floating-top')) {
    const newHtmlClass = htmlClass.replace('om-position-floating-top', '');
    document.documentElement.className = newHtmlClass;
    document.documentElement.style.paddingTop = '0px';
  }
};

export default removeOptinmonsterClasses;
