import { useState, useEffect } from 'react';
import useIsClient from './useIsClient';

const useWindowDimensions = () => {
  const [innerHeight, setInnerHeight] = useState(null);
  const [innerWidth, setInnerWidth] = useState(null);
  const isClient = useIsClient();

  useEffect(() => {
    setInnerHeight(window.innerHeight);
    setInnerWidth(window.innerWidth);

    const resizeHandler = () => {
      setInnerHeight(window.innerHeight);
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);
  return isClient ? { innerHeight, innerWidth } : { innerHeight: null, innerWidth: null };
};

export default useWindowDimensions;
