/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import './static/fonts/fonts.css';
import './src/styles/STtheme/_var.css';
import './src/styles/STtheme/global.less';
import { SnackbarProvider } from 'notistack';
import TaxonomyContext from 'utils/TaxonomyContext';
import AnnouncementBarContext from './src/utils/AnnouncementBarContext';
import { HeaderNavContextOverlay } from './src/utils/HeaderNavContext';
import { SweetScrollContextOverlay } from './src/utils/SweetScrollContext';
import GlobalDemoForm from './src/components/Form/GlobalDemoForm';
import GlobalModalForm from './src/components/Form/GlobalModalForm';
import removeOptinmonsterClasses from './src/utils/removeOptinmonsterClasses';
import NavLogoContext from './src/utils/NavLogoContext';
import { EditableProvider } from './src/utils/EditableWidgetContext';

const onInitialClientRender = () => {
  Object.defineProperty(document, 'referrer', {
    get: function () {
      return window.previousPath;
    },
  });

  window.addEventListener('message', (event) => {
    if (event.data.functionName === 'toggleModal') {
      window.toggleModal(3781);
    }
    if (event.data.dataLayerObj) {
      dataLayer.push({ ...event.data.dataLayerObj, cta_url: window.location.href });
    }
  });
};

const onRouteUpdate = ({ location, prevLocation }) => {
  window.dispatchEvent(new Event('routechange'));
  window.locationHistory = window.locationHistory || [document.referrer];
  window.locationHistory.push(window.location.href);
  window.previousPath = window.locationHistory[window.locationHistory.length - 2];
  dataLayer.push({ event: 'optimize.activate' });

  const handleScroll = () => {
    const hash = location.hash.trim();
    if (hash) {
      const element = document.querySelector(hash);

      if (element) {
        const headerOffset = 150;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
    window.removeEventListener('load', handleScroll);
  };

  if (location.hash) {
    window.addEventListener('load', handleScroll);
    document.dispatchEvent(new Event('hashchange'));
  }

  if (window.om70344_62468) {
    window.om70344_62468.reset();
  }

  if (!prevLocation && !location?.hash) {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }
};

const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  window.history.scrollRestoration = 'manual';

  if (location.hash) {
    return false;
  }

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });

  return false;
};

const onPreRouteUpdate = () => {
  removeOptinmonsterClasses();
};

let currLogoType = 'Default';
let prevLogoType = 'initial';

const wrapPageElement = ({ element, props }) => {
  const currLogo =
    props?.data?.contentfulPage?.components?.find(
      (x) => x.__typename === 'ContentfulHeader' && !x.isAnchorMenu,
    )?.logoType || 'Default';

  prevLogoType = prevLogoType === 'initial' ? currLogo : currLogoType;
  currLogoType = currLogo;

  return (
    <TaxonomyContext.Provider value={{ ...props?.data?.contentfulPage?.seo?.taxonomy }}>
      <NavLogoContext.Provider value={{ currLogoType, prevLogoType }}>
        <AnnouncementBarContext.Provider
          value={{
            hideAnnouncementBar:
              props?.data?.contentfulPage?.seo?.hideAnnouncementBar ||
              props?.data?.contentfulPage?.components?.some((x) => x?.hideAnnouncementBar),
          }}
        >
          <EditableProvider>
            <SnackbarProvider>
              <SweetScrollContextOverlay>
                <GlobalDemoForm />
                <GlobalModalForm />
                <HeaderNavContextOverlay {...props}>{element}</HeaderNavContextOverlay>
              </SweetScrollContextOverlay>
            </SnackbarProvider>
          </EditableProvider>
        </AnnouncementBarContext.Provider>
      </NavLogoContext.Provider>
    </TaxonomyContext.Provider>
  );
};

export {
  onInitialClientRender,
  onPreRouteUpdate,
  onRouteUpdate,
  wrapPageElement,
  shouldUpdateScroll,
};
