import React from 'react';
import { getMarketoFormId } from 'utils/marketo';
import PricingForm from 'components/Form/PricingForm';
import MarketoEmbedded from 'components/Form/MarketoEmbedded';
import DynamicMarketoForm from 'components/Form/DynamicMarketoForm';
import SwiftyForm2 from 'components/Form/SwiftyForm/v2';
import isNil from 'utils/isNil';
import useAltForms from '../../../hooks/useAltForms';

const removeNilProps = (obj) =>
  Object.keys(obj).forEach((key) => {
    if (isNil(obj[key])) {
      delete obj[key];
    }
  });

const getMarketoId = (form) => form?.marketoFormId || parseInt(getMarketoFormId(form));

const MarketoForm = ({
  dataPosition,
  shadow,
  inlineMarketingForm,
  formType,
  closeModal,
  theme,
  useEmbeddedForm,
  ...otherProps
}) => {
  const { useAlternateForms } = inlineMarketingForm || {};
  const alternateForms = useAltForms();

  const renderForm = (form) => {
    const { marketoForm } = form || {};
    const id = getMarketoId(marketoForm);

    const {
      formTitle: title,
      formBackgroundColor: backgroundColor,
      formTitleColor: titleColor,
      formButtonTitle: buttonLabel,
      name: formName,
      ...otherMarketoFormProps
    } = form || {};

    removeNilProps(otherProps);
    removeNilProps(otherMarketoFormProps);

    const props = {
      backgroundColor,
      titleColor,
      title,
      buttonLabel,
      formName,
      ...otherMarketoFormProps,
      ...otherProps,
      ignoreSubmitted: inlineMarketingForm?.ignoreSubmitted,
    };

    if (id === 3888) {
      return (
        <PricingForm
          dataPosition={dataPosition}
          {...props}
          formType={formType || 'Pricing Form'}
          marketoFormId={id}
          closeModal={closeModal}
        />
      );
    }
    if (id === 3781 || id === 3905) {
      return (
        <SwiftyForm2
          dataPosition={dataPosition || (closeModal ? 'Modal - Demo Form' : 'Body - Demo Form')}
          {...props}
          titleSize="28px"
          formType="Swifty Form 2"
          marketoFormId={id}
          style={{ boxShadow: '0px 30px 60px rgba(11, 26, 33, 0.15)' }}
        />
      );
    }
    if ((useEmbeddedForm || inlineMarketingForm?.useEmbeddedForm) === true) {
      return (
        <MarketoEmbedded
          dataPosition={dataPosition}
          {...props}
          formType={formType}
          marketoFormId={id}
          modalMode={false}
          closeModal={closeModal}
          theme={theme}
        />
      );
    }
    return (
      <DynamicMarketoForm
        dataPosition={dataPosition}
        {...props}
        titleColor="black"
        marketoFormId={id}
        formType={formType || 'Dynamic Marketo Form'}
        style={{ boxShadow: shadow ? '0px 30px 60px rgba(11, 26, 33, 0.15)' : 'none' }}
      />
    );
  };

  return (
    <>
      {useAlternateForms &&
        alternateForms.map((form, index) => (
          <div
            className={`alt-form-${index + 1}`}
            key={`alt-form-${index}`}
            data-cy={`altform${index + 1}`}
          >
            {renderForm(form)}
          </div>
        ))}
      <div className={useAlternateForms ? 'primary-form' : 'marketo-form'} data-cy="primaryForm">
        {renderForm(inlineMarketingForm)}
      </div>
    </>
  );
};

export default MarketoForm;

MarketoForm.defaultProps = {
  shadow: true,
  width: '100%',
  buttonType: 'primary',
};
